import React, { createContext, useCallback, useContext, useEffect } from "react";
import { toast } from 'react-toastify';
import logger from '../../../util/logger';
import { useAppState } from "../useAppState";
import { useSession } from "../useSession";
import { useRecorder } from "../recording/useRecorder";

export function useTimestamps() {
  const context = useContext(TimestampsContext);
  if (!context) {
    throw new Error('useTimestamps must be used within the TimestampsProvider');
  }
  return context;
}

export const TimestampsContext = createContext<{
  createTimestamp: () => Promise<void>;
}>(null!);

export function TimestampsProvider(props: React.PropsWithChildren<any>) {
  const { isRecording, getRecordingsInfo: getRecordingInfo } = useRecorder();
  const { api } = useAppState();
  const { participantInfo } = useSession();

  const createTimestamp = useCallback(async () => {
      if (isRecording) {
        const recordingsInfo = await getRecordingInfo();
        const recording = recordingsInfo.find(recording => !!recording.sessionRecordingId)
        if (recording && recording.sessionRecordingId) {
          logger.info('Space bar pressed while recording... creating timestamp');
          await api.createTimestamp(participantInfo.id, recording.sessionRecordingId, Date.now(), Date.now())
          toast.success('Timestamp Created')
        }
      }
    },
    [isRecording, participantInfo, api, getRecordingInfo]
  );


  useEffect(() => {
    async function handleSpace(event: any) {
      if (event.code === 'Space') {
        await createTimestamp()
      }
    }

    document.addEventListener('keyup', handleSpace)

    return () => {
      document.removeEventListener('keyup', handleSpace)
    }
  }, [createTimestamp]);

  return (
    <TimestampsContext.Provider value={{
      createTimestamp
    }}>
      {props.children}
    </TimestampsContext.Provider>
  );
}
