import { LocalAudioTrack, LocalVideoTrack, RemoteAudioTrack, RemoteVideoTrack } from 'twilio-video';
import { useCallback, useEffect, useState } from 'react';
import { ensureMediaPermissions } from '../../util/app';
import logger from '../../util/logger';
import useVideoContext from './video/useVideoContext';

type TrackType = LocalAudioTrack | LocalVideoTrack | RemoteAudioTrack | RemoteVideoTrack | undefined;

export function useIsTrackEnabled(track: TrackType) {
  const [isEnabled, setIsEnabled] = useState(track ? track.isEnabled : false);

  // @ts-ignore
  useEffect(() => {
    setIsEnabled(track ? track.isEnabled : false);

    if (track) {
      const setEnabled = () => setIsEnabled(true);
      const setDisabled = () => setIsEnabled(false);
      track.on('enabled', setEnabled);
      track.on('disabled', setDisabled);
      return () => {
        track.off('enabled', setEnabled);
        track.off('disabled', setDisabled);
      };
    }
  }, [track]);

  return isEnabled;
}

export function useLocalAudioToggle() {
  const { localTracks } = useVideoContext();
  const audioTrack = localTracks.find((track) => track.kind === 'audio') as LocalAudioTrack;
  const isEnabled = useIsTrackEnabled(audioTrack);

  const toggleAudioEnabled = useCallback(() => {
    if (audioTrack) {
      audioTrack.isEnabled ? audioTrack.disable() : audioTrack.enable();
    }
  }, [audioTrack]);

  return [isEnabled, toggleAudioEnabled] as const;
}

export function useLocalVideoToggle() {
  const {
    room: { localParticipant },
    localTracks,
  } = useVideoContext();

  const videoTrack = localTracks.find((track) => track.name.includes('camera')) as LocalVideoTrack | undefined;

  const toggleVideoEnabled = useCallback(() => {
    if (!videoTrack) {
      logger.warn('Cannot toggle video since video track does not exist');
      return;
    }

    if (videoTrack.isEnabled) {
      if (localParticipant) {
        const localTrackPublication = localParticipant.unpublishTrack(videoTrack);
        // TODO: remove when SDK implements this event. See: https://issues.corp.twilio.com/browse/JSDK-2592
        localParticipant.emit('trackUnpublished', localTrackPublication);
      }
      videoTrack.disable();
    } else {
      // @ts-ignore
      videoTrack.enable();

      if (localParticipant) {
        // @ts-ignore
        localParticipant.publishTrack(videoTrack as any, { priority: 'low' });
      }
    }
  }, [videoTrack, localParticipant]);

  const isEnabled = useIsTrackEnabled(videoTrack);
  return [isEnabled, toggleVideoEnabled] as const;
}

export function useDevices() {
  const [devices, setDevices] = useState<MediaDeviceInfo[]>([]);

  useEffect(() => {
    const getDevices = () =>
      ensureMediaPermissions().then(() =>
        navigator.mediaDevices.enumerateDevices().then((devices) => {
          setDevices(devices);
        })
      );
    navigator.mediaDevices.addEventListener('devicechange', getDevices);
    getDevices();

    return () => {
      navigator.mediaDevices.removeEventListener('devicechange', getDevices);
    };
  }, []);

  return devices;
}

export function useAudioInputDevices() {
  const devices = useDevices();
  return devices.filter((device) => device.kind === 'audioinput');
}

export function useVideoInputDevices() {
  const devices = useDevices();
  return devices.filter((device) => device.kind === 'videoinput');
}

export function useAudioOutputDevices() {
  const devices = useDevices();
  return devices.filter((device) => device.kind === 'audiooutput');
}
