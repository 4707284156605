import React, { useEffect, useRef } from 'react';
import { Track } from 'twilio-video';
import styled from 'styled-components';

interface VideoTrackProps {
  track: any;
  isLocal?: boolean;
  priority?: Track.Priority | null;
  videoStyle?: React.CSSProperties;
}

export default function VideoTrack({ track, isLocal, priority, videoStyle }: VideoTrackProps) {
  const ref = useRef<HTMLVideoElement>(null!);

  useEffect(() => {
    const el = ref.current;
    el.muted = true;
    track.attach(el);

    return () => {
      track.detach(el);
    };
  }, [track]);

  useEffect(() => {
    // @ts-ignore
    if (track.setPriority && priority) {
      track.setPriority(priority);
    }

    return () => {
      if (track.setPriority && priority) {
        // Passing `null` to setPriority will set the track's priority to that which it was published with.
        track.setPriority(null);
      }
    };
  }, [track, priority]);

  if (!track) return null;

  // The local video track is mirrored.
  const isScreenShare = track.name.includes('screenshare');
  const isFrontFacing = track.mediaStreamTrack.getSettings().facingMode !== 'environment';

  const style = isLocal && !isScreenShare && isFrontFacing ? { transform: 'rotateY(180deg)' } : {};

  if (videoStyle) {
    Object.assign(style, videoStyle);
  }

  return <Video ref={ref} style={style} />;
}

const Video = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 4px;
`;
