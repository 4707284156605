import React, { Fragment } from 'react';
import styled from 'styled-components';
import useUploader, {useUploadStats} from '../hooks/useUploader';
// @ts-ignore
import { bytesToSize } from 'recordrtc';
import ActionButton from '../components/ui/ActionButton';
import { useHistory } from 'react-router';
import { useAppState } from '../hooks/useAppState';
import RejoinSession from '../components/RejoinSession';
import { usePlatform } from '../hooks/platform/usePlatform';

function Uploader() {
  const { hasActiveUploads, checkingUploads } = useUploader();
  const history = useHistory();
  const state = useAppState();
  const { platformHelpers } = usePlatform();
  const stats = useUploadStats();

  const uploads = stats
    .sort((a, b) => b.createdDateMs - a.createdDateMs)
    .map((item) => {
      let status = <Fragment>Uploading - {item.uploadedSizeBytes ? Math.round((item.uploadedSizeBytes / item.totalSizeBytes) * 100) : 0}%</Fragment>;

      if (item.status === 'RESTARTING') {
        status = <Fragment>Restarting...</Fragment>;
      }

      if (item.status === 'ERROR') {
        status = <Fragment>Error</Fragment>;
      }

      if (item.status === 'PAUSED') {
        status = <Fragment>Paused</Fragment>;
      }

      if (item.status === 'STARTING' || item.status === 'CREATED') {
        status = <Fragment>Starting...</Fragment>;
      }

      if (item.status === 'DONE') {
        status = <Fragment>Done</Fragment>;
      }

      return (
        <tr key={item.filename}>
          <td>You</td>
          <td>
            {bytesToSize(item.uploadedSizeBytes)} / {bytesToSize(item.totalSizeBytes)}
          </td>
          <td>
            <UploadStatus>{status}</UploadStatus>
          </td>
        </tr>
      );
    });

  let headline = 'Your recordings have been uploaded';

  if (hasActiveUploads) {
    headline = 'Uploading your recordings...';
  }

  if (checkingUploads && !hasActiveUploads) {
    return null;
  }

  return (
    <Container>
      <Content>
        <Headline>{headline}</Headline>
        {hasActiveUploads && (
          <Table>
            <thead>
              <tr>
                <th>Recording</th>
                <th>Size</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>{uploads}</tbody>
          </Table>
        )}
        <Buttons>
          <ActionButton text="Join new session" icon="door-open" type="return" onClick={() => history.push('/')} />
          {state.wasHost && (
            <ActionButton
              text="Go the the dashboard"
              onClick={() => {
                const url =
                  process.env.WELDER_BUILD_ENVIRONMENT === 'production' ? 'https://app.getwelder.com/sessions' : 'https://app.staging.getwelder.com/sessions';
                platformHelpers.openUrl(url);
              }}
              icon="browser"
            />
          )}
          {state.lastCall && <RejoinSession />}
        </Buttons>
      </Content>
    </Container>
  );
}

export default Uploader;

const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Content = styled.div`
  max-width: 750px;
  width: 100%;
`;

const Headline = styled.div`
  color: #000000;
  font-size: 48px;
  font-weight: 400;
  margin: 0;
  margin-bottom: 40px;
`;

const Table = styled.table`
  width: 100%;
  margin-bottom: 40px;

  th {
    color: #949ba3;
    font-size: 18px;
    font-weight: 800;
    line-height: 29.63px;
    padding-bottom: 20px;
    text-align: left;
  }

  th:first-child {
    width: 34%;
  }

  th:nth-child(2) {
    width: 44%;
  }

  td {
    color: #000000;
    font-size: 16px;
    font-weight: 600;
    line-height: 26.34px;
    padding-bottom: 25px;
  }
`;

const UploadStatus = styled.p`
  margin: 0;
  color: #d0891e;
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  border-radius: 28px;
  background-color: #f2dfc3;
  padding: 6px 13px;
  display: inline-block;
  min-width: 150px;
  text-align: center;
`;

const Buttons = styled.div`
  display: flex;
  align-items: center;

  > * {
    margin-right: 20px;
  }

  > *:last-child {
    margin-right: 0px;
  }
`;
