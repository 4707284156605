import React, { useEffect } from 'react';
import styled from 'styled-components';
import Modal from 'react-modal';
import { useRecorder } from '../../hooks/recording/useRecorder';
import GridLayout from './layout/GridLayout';
import useCallControls from '../../hooks/controls/useCallControls';
import FeatureMenu from '../../components/FeatureMenu';
import { useSession } from '../../hooks/useSession';
import { usePlatform } from '../../hooks/platform/usePlatform';
import DominantLayout from './layout/DominantLayout';
import { useAllRemoteTracks } from '../../hooks/participants/participant';
import AudioTrack from '../../components/AudioTrack';
import ModalClose from '../../components/ui/ModalClose';
import PreviewRecordingModal from '../../components/PreviewRecordingModal';
import RecordingBlock from '../../components/RecordingBlock';
import useCountdown from '../../hooks/recording/useCountdown';


function CallRoom() {
  const { isRecording, scheduleRecording, isStarting, recordingStartTime, isTesting, scheduleTestRecording, toggleTestingModal, testingModalOpen } = useRecorder();
  const { platformHelpers } = usePlatform();
  const session = useSession();
  const { isHiddenUI, toggleUIHide, callLayout } = useCallControls();
  const remoteTracks = useAllRemoteTracks();
  const audioFeeds = remoteTracks.filter((item) => item.track.kind === 'audio');
  const countdown = useCountdown(isStarting, recordingStartTime)

  useEffect(() => {
    platformHelpers.enteredCall();

    return () => {
      platformHelpers.leftCall();
    };
  }, []);

  useEffect(() => {
    return () => {
      toggleUIHide(false);
    };
  }, []);


  const audioElements = audioFeeds.map((item) => {
    return <AudioTrack key={item.track.sid} track={item.track} />;
  });

  const view = callLayout === 'grid' ? <GridLayout /> : <DominantLayout />;

  return (
    <Container>
      {isStarting && countdown > 0 && (
        <CountdownContainer>
          <p>{countdown}</p>
        </CountdownContainer>
      )}

      {view}

      {!isHiddenUI && (
        <div>
          <RecordContainer>
            <RecordingBlock isHost={session.isHost} isRecording={isRecording} isTesting={isTesting} isStarting={isStarting} scheduleRecording={scheduleRecording} scheduleTestRecording={scheduleTestRecording} />
          </RecordContainer>
        </div>
      )}

      <FeatureMenu />
      {audioElements}

      <Modal overlayClassName="Overlay" className="Modal" isOpen={testingModalOpen} onRequestClose={() => toggleTestingModal(false)}>
        <ModalClose onClick={() => toggleTestingModal(false)} />
        <PreviewRecordingModal toggleModal={toggleTestingModal} previewUrl={session.sessionInfo.dashboardUrl} />
      </Modal>
    </Container>
  );
}

export default CallRoom;

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const RecordContainer = styled.div`
  position: absolute;
  width: fit-content;
  bottom: 35px;
  left: 50%;
  transform: translateX(-50%);
`;

const CountdownContainer = styled.div`
  width: 100vw;
  height: 100vh;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;

  p {
    color: white;
    margin: 0;
    font-size: 362px;
  }
`;