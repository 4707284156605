import React from 'react';
import styled from 'styled-components';
import Headline from './ui/Headline';
import ActionButton from './ui/ActionButton';

interface ModalProps {
  previewUrl: string;
  toggleModal: (toggle: boolean) => void;
}

function PreviewRecordingModal(props: ModalProps) {
  return (
    <Container>
      <Headline text="Test recording done" icon='check-circle' />
      <Buttons>
        <ActionButton
          text="Preview recorded files"
          icon="eye"
          onClick={() => {
            props.toggleModal(false);
            setTimeout(() => {
              window.open(props.previewUrl, '_blank')
            }, 0)
          }}
        />
      </Buttons>
      <InfoText>
        It may take a few moments in the dashboard to process the test recordings. Thanks for your patience.
      </InfoText>
    </Container>
  );
}

export default PreviewRecordingModal;

const Container = styled.div`
  max-width: 582px;
`;

const Buttons = styled.div`
  display: flex;

  > *:first-child {
    margin-right: 22px;
  }
`;

const InfoText = styled.p`
  margin: 0;
  color: #c5ccd3;
  font-size: 14px;
  font-weight: 600;
  line-height: 22.97px;
  margin-top: 20px;
`;
