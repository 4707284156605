import React, { createContext, useContext } from 'react';
import { IPlatformHelpers } from '../../platform/PlatformHelpers';
import { IPlatformRecorder } from '../../platform/PlatformRecorder';
import {IPlatformUploader} from "../../platform/PlatformUploader";

export function usePlatform() {
  const context = useContext(PlatformContext);

  if (!context) {
    throw new Error('usePlatform must be used within the PlatformProvider');
  }
  return context;
}

interface PlatformInterfaces {
  platformRecorder: IPlatformRecorder;
  platformUploader: IPlatformUploader;
  platformHelpers: IPlatformHelpers;
}

export interface PlatformContextType extends PlatformInterfaces {
  platform: 'web' | 'native';
}

export const PlatformContext = createContext<PlatformContextType>(null!);

export function PlatformProvider(props: React.PropsWithChildren<{}> & PlatformInterfaces) {
  return (
    <PlatformContext.Provider
      value={{
        platformRecorder: props.platformRecorder,
        platformUploader: props.platformUploader,
        platformHelpers: props.platformHelpers,
        platform: 'native',
      }}
    >
      {props.children}
    </PlatformContext.Provider>
  );
}
