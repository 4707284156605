import React, { useEffect } from 'react';
import { VideoProvider } from '../../hooks/video/provider';
import WaitingRoom from './WaitingRoom';
import styled from 'styled-components';
import Menu from '../../components/Menu';
import CallRoom from './CallRoom';
import { toast } from 'react-toastify';
import { RecorderProvider } from '../../hooks/recording/useRecorder';
import { DataChannelProvider } from '../../hooks/useDataChannel';
import useCallControls, { CallControlsProvider } from '../../hooks/controls/useCallControls';
import logger from '../../../util/logger';
import { TwilioError } from 'twilio-video';
import Sentry from '../../../util/sentry';
import { SessionProvider } from '../../hooks/useSession';
import { useAppState } from '../../hooks/useAppState';
import { Redirect, useLocation, useParams } from 'react-router';
import { usePlatform } from '../../hooks/platform/usePlatform';
import PermissionReject from './PermissionRejected';
import { ScreenShareProvider } from '../../hooks/screen/useScreenShare';
import useVideoContext from '../../hooks/video/useVideoContext';
import useRoomState from '../../hooks/video/useRoomState';
import { ParticipantsProvider } from '../../hooks/participants/provider';
import { TimestampsProvider } from "../../hooks/timestamps/useTimestamps";
import SinglePlayerRoom from './SinglePlayerRoom';

function RoomWrapper() {
  const state = useAppState();
  const { slug } = useParams<{slug?: string}>();
  const location = useLocation();

  function onError(error: TwilioError) {
    // RoomMaxParticipantsExceededError
    if (error.code === 53105) {
      toast.error('This call is already full');
      logger.warn('Tried to join full room:', error);
      return;
    }

    // RoomConnectFailedError;
    if (error.code === 53104) {
      logger.error(error);
      Sentry.captureException(error);
      toast.error('Could not connect to call');
      return;
    }

    logger.error(error);
    Sentry.captureException(error);
    toast.error('There was an error with your call');
  }

  if (!state.activeSessionData) {
    const url = `/j/${location.search}#${slug}`;
    return <Redirect to={url} />;
  }

  return (
    <SessionProvider session={state.activeSessionData}>
      <VideoProvider onError={onError}>
        <DataChannelProvider>
          <ParticipantsProvider>
            <ScreenShareProvider>
              <RecorderProvider>
                <CallControlsProvider>
                  <TimestampsProvider>
                    <Room />
                  </TimestampsProvider>
                </CallControlsProvider>
              </RecorderProvider>
            </ScreenShareProvider>
          </ParticipantsProvider>
        </DataChannelProvider>
      </VideoProvider>
    </SessionProvider>
  );
}

function Room() {
  const state = useRoomState();
  const { activeSessionData } = useAppState()
  const { onLeaveRoomRef, showPermissionPage } = useVideoContext();
  const { isHiddenUI } = useCallControls();
  const { platformHelpers } = usePlatform();

  useEffect(() => {
    return () => {
      // since the leave room callback is changing we need to keep it inside ref
      // this way dependency array of this useEffect can be empty and only called on unmount of component
      onLeaveRoomRef.current();
    };
  }, []);

  useEffect(() => {
    platformHelpers.enteredRoom();

    return () => {
      platformHelpers.leftRoom();
    };
  }, []);

  if (showPermissionPage) {
    return <PermissionReject />;
  }

  if (activeSessionData.sessionInfo.sessionType === 'SINGLE_PLAYER') {
    return (
      <Container>
        <SinglePlayerRoom />
        <Menu />
      </Container>
    )
  }

  return (
    <Container>
      {state === 'disconnected' ? <WaitingRoom /> : <CallRoom />}
      {!isHiddenUI && <Menu />}
    </Container>
  );
}

export default RoomWrapper;

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  background: black;
  overflow: hidden;
`;
