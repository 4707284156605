import { EventEmitter } from 'events';
import { IPlatformHelpers } from '../core/platform/PlatformHelpers';
import { BrowserRouter } from 'react-router-dom';
import { ensureStoragePermissions } from '../util/app';
import logger from '../util/logger';

export default class WebHelpers extends EventEmitter implements IPlatformHelpers {
  platform: 'WEB' = 'WEB';

  get router() {
    return BrowserRouter;
  }

  async leftCall() {
    const ok = await ensureStoragePermissions();
    logger.info('tried to request storage permission and the result is: ', ok);
  }

  async enteredCall() {
    // do nothing.
  }
  async enteredRoom() {
    // do nothing.
  }
  async leftRoom() {
    // do nothing.
  }

  async openUrl(url: string) {
    window.open(url, '_blank');
  }
}
