import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Tooltip } from 'react-tippy';
import styled from 'styled-components';
import { useScreenShare } from '../hooks/screen/useScreenShare';
import VideoTrack from './VideoTrack';
// @ts-ignore
import StopScreen from '../assets/stop-screen.svg';
import { useRecorder } from '../hooks/recording/useRecorder';



interface ScreenShareBlockProps {}


export default function ScreenShareBlock(props: ScreenShareBlockProps) {
  const [isHover, toggleHover] = useState(false);
  const { isScreenSharing, screenShareTrack, startScreenshare, stopScreenshare } = useScreenShare()
  const { isRecording } = useRecorder();

  if (!isScreenSharing) {
    const tooltip = isRecording ? 'Cannot start screen share durning the recording. Please stop the recording first.' : 'Screen will also be recorded in high quality'

    return (
      <div>
        <Tooltip distance={15} position="top" title={tooltip} theme="light">
          <Button disabled={isRecording} onClick={() => startScreenshare(false)}>
            <FontAwesomeIcon icon='desktop' />
            Share Screen
          </Button>
        </Tooltip>
      </div>
    )
  }

  return (
    <ScreenContainer onMouseEnter={() => toggleHover(true)} onMouseLeave={() => toggleHover(false)}>
      <VideoTrack track={screenShareTrack} />
      {isHover && (
        <StopButton onClick={() => stopScreenshare()}>
          <img src={StopScreen} alt="Stop screen sharing" />
          Stop screen share
        </StopButton>
      )}
    </ScreenContainer>
  )
}

const ScreenContainer = styled.div`
  max-width: 450px;
  width: 100%;
  border-radius: 6px;
  overflow: hidden;
  position: relative;
`

const Button = styled.button`
  box-shadow: none;
  outline: none;
  appearance: none;
  border: none;
  border-radius: 16px;
  background-color: rgba(255, 255, 255, 0.7);
  color: #000000;
  font-size: 18px;
  font-weight: 700;
  text-align: left;
  padding: 15px 25px;
  user-select: none;
  cursor: pointer;
  display: flex;
  align-items: center;

  svg {
    margin-right: 7px;
  }

  &:hover {
    background-color: rgba(255, 255, 255, 0.9);
  }

  ${(props) =>
    props.disabled &&
    `
    background-color: rgba(255, 255, 255, 0.4);
  `}
`
const StopButton = styled.button`
  box-shadow: none;
  outline: none;
  appearance: none;
  border: none;
  border-radius: 16px;
  background-color: white;
  box-shadow: 0 4px 17px rgba(0, 0, 0, 0.53);
  border-radius: 16px;
  color: #000000;
  font-size: 18px;
  font-weight: 700;
  text-align: left;
  padding: 15px 25px;
  user-select: none;
  cursor: pointer;
  display: flex;
  align-items: center;

  position: absolute;
  z-index: 2;
  bottom: 16px;
  left: 50%;
  white-space: nowrap;
  transform: translateX(-50%);

  svg, img {
    margin-right: 7px;
  }

  &:hover {
    background-color: rgba(255, 255, 255, 0.9);
  }
`