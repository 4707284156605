import Dexie from 'dexie';
import { RecordingType } from '../../core/platform/PlatformRecorder';

export interface IRecording {
  id?: number;
  apiRecordingId?: string;
  storageFileId?: string;
  sessionRecordingId?: string;
  participantId: string;

  recordingInProgress: boolean;
  totalBytes: number;
  type: RecordingType;
  uploadedBytes: number;
  createdTimestamp: number;
}

export interface IRecordingBlob {
  id?: number;
  orderingNumber: number;
  buffer: ArrayBuffer;
  blobSizeBytes: number;
  recordingId: number;
  uploading: number;
}

export interface ISessionItem {
  id?: number;
  sessionId: string;
  participantId: string;
}

class WelderDatabase extends Dexie {
  public recordings: Dexie.Table<IRecording, number>;
  public recordingBlobs: Dexie.Table<IRecordingBlob, number>;
  public sessionList: Dexie.Table<ISessionItem, number>;

  public constructor() {
    super('WelderDatabase');
    this.version(14).stores({
      recordings: '++id, storageFileId, apiRecordingId',
      recordingBlobs: '++id, recordingId, [recordingId+uploading]',
      sessionList: '++id, &sessionId, participantId'
    }).upgrade(trans => {
      return trans.table('recordings').toCollection().modify(recording => {
        if (!!!recording.type) {
          recording.type = 'VIDEO_LOCAL'
        }
      })
    })

    this.recordings = this.table('recordings');
    this.recordingBlobs = this.table('recordingBlobs');
    this.sessionList = this.table('sessionList')
  }
}

const db = new WelderDatabase();
(window as any).db = db;

export async function createRecording(recording: IRecording) {
  return db.recordings.add(recording);
}

export async function addRecordingBlob(recordingId: number, recordingBlob: IRecordingBlob, totalBytes: number) {
  return db.transaction('rw', db.recordings, db.recordingBlobs, async () => {
    await db.recordings.update(recordingId, {
      totalBytes: totalBytes,
    });
    return db.recordingBlobs.add(recordingBlob);
  });
}

export async function getBlob(id: number) {
  return db.recordingBlobs.get(id);
}

export async function unmarkUploadingBlobs(recordingId: number) {
  await db.recordingBlobs.where('recordingId').equals(recordingId).modify({ uploading: 0 });
}

export async function markBlobAsUploading(blobId: number) {
  return db.recordingBlobs.update(blobId, {
    uploading: 1,
  });
}

export async function deleteUploadedBlob(blobId: number, storageFileId: string) {
  await db.transaction('rw', db.recordings, db.recordingBlobs, async () => {
    const recording = await db.recordings.get({ storageFileId });
    const blob = await db.recordingBlobs.get(blobId);

    await db.recordings.update(recording!.id!, {
      uploadedBytes: blob!.blobSizeBytes + recording!.uploadedBytes,
    });

    await db.recordingBlobs.delete(blobId);
  });
}

export async function getRecordingBlobCount(recordingId: number) {
  return db.recordingBlobs.where('recordingId').equals(recordingId).count();
}

export async function getRecordingBlobsIdsByLimit(recordingId: number, limitTo: number, from = 0) {
  return db.recordingBlobs.where({ recordingId: recordingId, uploading: 0 }).offset(from).limit(limitTo).primaryKeys();
}

export async function addApiIdToRecording(recordingId: number, storageFileId: string, apiRecordingId: string, sessionRecordingId: string) {
  return db.recordings.update(recordingId, {
    storageFileId: storageFileId,
    apiRecordingId,
    sessionRecordingId
  });
}

export async function markRecordingAsDone(recordingId: number) {
  return db.recordings.update(recordingId, {
    recordingInProgress: false,
  });
}

export async function resetAllRecordingsStatuses() {
  return db.recordings.toCollection().modify({ recordingInProgress: false });
}

export async function getRecording(recordingId: number) {
  return db.recordings.get(recordingId);
}

export async function deleteRecording(recordingId: number) {
  return db.recordings.delete(recordingId);
}

export async function getAllRecordingsIds() {
  return db.recordings.toCollection().primaryKeys();
}

export async function setParticipantForSession(session: ISessionItem) {
  return db.sessionList.add(session)
}

export async function getSessionParticipant(sessionId: string) {
  return db.sessionList.get({sessionId: sessionId})
}
