import React, { useCallback, useState } from 'react';
import logger from '../../../util/logger';
import VideoTrack from '../../components/VideoTrack';
import { LocalVideoTrack } from 'twilio-video';
import styled from 'styled-components';
import ActionButton from '../../components/ui/ActionButton';
import { useExternalRecordingState } from '../../hooks/recording/useRecorder';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSession } from '../../hooks/useSession';
import useVideoContext from '../../hooks/video/useVideoContext';


function WaitingRoom() {
  const { localTracks, connect, isConnecting } = useVideoContext();
  const [isFetchAccess, toggleIsFetching] = useState(false);

  const setRecordingStateFromRemote = useExternalRecordingState();
  const { getAccessToken, isHost } = useSession();

  const videoTrack = localTracks.find((track) => track.name.includes('camera')) as LocalVideoTrack;

  const joinCall = useCallback(async () => {
    let info: any;

    try {
      toggleIsFetching(true);
      info = await getAccessToken();
    } catch (e) {
      logger.error('Could not get access to Twilio room for participant');
      throw e;
    } finally {
      toggleIsFetching(false);
    }

    logger.info(`Joining Twilio room ${info.twilioRoomName} with access token ${info.twilioAccessToken}`)
    await connect(info.twilioAccessToken, info.twilioRoomName);

    await setRecordingStateFromRemote();
  }, [connect, setRecordingStateFromRemote, toggleIsFetching]);

  const buttonText = isHost ? 'Join the call as creator' : 'Join the call'

  const leaveIcon = <FontAwesomeIcon icon='sign-out'/>
  const bulletPoints = isHost ? (
    <BulletPoints>
      <h3>Some tips for you (as a creator)</h3>
      <div><span>🎧</span> <p>Use headphones</p></div>
      <div><span>☀️</span>️ <p>Have a good lighting (daylight is ideal)</p></div>
      <div><span>👆</span> <p>Hover over your guests to check their cam/mic and uploading status</p></div>
      <div><span>🌍</span> <p>Welder works best in Google Chrome</p></div>
      <div><span>🚫</span> <p>Close all other recording apps and make sure no other app is using your camera or microphone</p></div>
      <div><span>🚪</span> <p>Use the leave button {leaveIcon} to end the call for everyone. It will take them to a screen to finish the uploading.</p></div>
      <div><span>🕵️‍♂️</span> <p>Do not use private / anonymous mode in your browser</p></div>
    </BulletPoints>
  ) : (
    <BulletPoints>
      <h3>4 tips to have an awesome interview</h3>
      <div><span>🎧</span> <p>Use headphones</p></div>
      <div><span>☀️️</span>️ <p>Have a good lighting (daylight is ideal)</p></div>
      <div><span>🚫</span> <p>Do not close the tab to leave the call, use the leave button {leaveIcon}</p></div>
      <div><span>🕵️‍♂️</span> <p>Do not use private / anonymous mode in your browser</p></div>
    </BulletPoints>
  )

  return (
    <Container>
      {videoTrack ? <VideoTrack isLocal track={videoTrack} /> : null}
      <Content>
        <h2>Enjoy your recording!</h2>
        <ActionButton id='join-call' text={isConnecting || isFetchAccess ? 'Connecting...' : buttonText} icon="sign-in-alt" type='full-width' onClick={joinCall} disabled={isConnecting} />
        {bulletPoints}
      </Content>
    </Container>
  );
}

export default WaitingRoom;

const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

const Content = styled.div`
  position: absolute;
  left: 80px;
  top: 50%;
  transform: translateY(-50%);
  max-width: 470px;
  width: 100%;
  background: #FAFAFA;
  border-radius: 33px;
  z-index: 1;
  padding: 40px;
  box-shadow: 0px 4px 20px -8px rgba(0, 0, 0, 0.25);

  @media screen and (max-height: 800px) {
    max-width: 620px;
  }

  h2 {
    font-weight: bold;
    font-size: 26px;
    line-height: 164.6%;
    color: #000000;
    margin: 0;
    margin-bottom: 20px;
  }
`

const BulletPoints = styled.div`
  margin-top: 50px;

  h3 {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 164.6%;
    color: #000000;
    margin: 0;
  }

  p, span {
    font-weight: 600;
    font-size: 16px;
    line-height: 164.6%;
    color: #000000;
  }

  div {
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
  }

  span {
    width: 22px;
    display: block;
    text-align: center;
    margin-right: 10px;
  }

  p {
    margin: 0;
    margin-left: 5px;

    svg {
      display: inline-block;
      margin-left: 1px;
    }
  }

  div:last-child {
    margin-bottom: 0;
  }
`
