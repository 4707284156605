import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppState } from '../hooks/useAppState';

export default function RejoinSession() {
  const state = useAppState();
  const lastCall = state.lastCall!;
  return (
    <RejoinContainer onClick={() => state.joinSession(lastCall.sessionSlug, lastCall.sessionHostToken)}>
      <FontAwesomeIcon icon="repeat-alt" />
      <p>Rejoin last session</p>
    </RejoinContainer>
  );
}

const RejoinContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  svg {
    margin-right: 7px;
    color: #000000;
    font-size: 16px;
    font-weight: 400;
  }

  p {
    color: #000000;
    font-size: 18px;
    font-weight: 600;
    margin: 0;
  }

  &:hover {
    p {
      text-decoration: underline;
    }
  }
`;
