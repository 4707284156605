import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import API from '../api';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import logger from '../../util/logger';
import { Session } from './useSession';
import { usePlatform } from './platform/usePlatform';
import { detect } from 'detect-browser';
import { getMachineIds } from '../../util/app';
import { setParticipantForSession, getSessionParticipant } from '../../util/app/db'

export function useAppState() {
  const context = useContext(StateContext);

  if (!context) {
    throw new Error('useAppState must be used within the AppStateProvider');
  }
  return context;
}

interface StateContextType {
  api: API;
  isEnteringRoom: boolean;
  wasHost: boolean;
  joinSession: (sessionSlug: string, hostToken: string | null) => Promise<void>;
  lastCall: { sessionSlug: string; sessionHostToken: string | null } | null;
  quitSession: () => Promise<void>;
  activeSessionData: Session | null;
}

export const StateContext = createContext<StateContextType>(null!);

export function AppStateProvider(props: React.PropsWithChildren<{ appVersion?: string }>) {
  const api = new API();
  const history = useHistory();
  const { platformHelpers } = usePlatform();

  const [isEnteringRoom, setIsEnteringRoom] = useState(false);
  const callInProgress = useMemo(() => {
    return history.location.pathname.startsWith('/call/');
  }, [history.location.pathname]);

  const [activeSessionData, setActiveSessionData] = useState<Session | null>(null);
  const [wasHost, setWasHost] = useState(false);
  const [lastCall, setLastCall] = useState<{ sessionSlug: string; sessionHostToken: string | null } | null>(null);

  const joinSession = useCallback(
    async (newSessionSlug: string, newHostToken: string | null) => {
      logger.info(`Joining new session... ${newSessionSlug} with host token: ${newHostToken}`);

      if (callInProgress) {
        logger.info('Cannot join session when call is in progress');
        return;
      }

      if (isEnteringRoom) {
        logger.info('Already joining session... skip');
        return;
      }

      async function getParticipantInfo(newSessionSlug: string) {
        const browser = detect();
        const machine = getMachineIds()

        try {
          const sessionInfo = await api.getSessionBySlug(newSessionSlug);
          let participantId = (await getSessionParticipant(sessionInfo.id))?.participantId
          let participantInfo: any;

          if (participantId) {
            logger.info('Reusing old participant from cache')
            participantInfo = await api.getSessionParticipant(participantId)
          } else {
            logger.info('No participant found in cache for this session. Creating an new one.')
            participantInfo = await api.createSessionParticipant(sessionInfo.id, newHostToken, platformHelpers.platform, browser, machine);
            await setParticipantForSession({sessionId: sessionInfo.id, participantId: participantInfo.id})
          }

          logger.info(`Entering session ${sessionInfo.id}`);

          setActiveSessionData({ sessionSlug: newSessionSlug, hostToken: newHostToken, sessionInfo, participantInfo });

          let url = `/call/${newSessionSlug}`;
          if (newHostToken) {
            url += `?host_token=${newHostToken}`;
          }

          history.push(url);

          if (newHostToken) {
            setWasHost(true);
          }

          setLastCall({ sessionSlug: newSessionSlug, sessionHostToken: newHostToken });
        } catch (e) {
          logger.error(e)
          toast.error('Could not join meeting');
          await quitSession();
          throw e;
        }
      }

      setIsEnteringRoom(true);
      try {
        await getParticipantInfo(newSessionSlug);
      } finally {
        setIsEnteringRoom(false);
      }
    },
    [callInProgress, isEnteringRoom]
  );

  const quitSession = useCallback(async () => {
    logger.info('Quiting session');
    setActiveSessionData(null);
  }, []);

  const openSessionRequest = useCallback(
    async (info: { host_token: string | undefined; session_slug: string }) => {
      await joinSession(info.session_slug, info.host_token || null);
    },
    [joinSession]
  );

  useEffect(() => {
    platformHelpers.on('open-session-request', openSessionRequest);

    return () => {
      platformHelpers.off('open-session-request', openSessionRequest);
    };
  }, [openSessionRequest]);

  useEffect(() => {
    logger.info('Is in call:', callInProgress);
  }, [callInProgress]);

  useEffect(() => {
    if (wasHost) {
      localStorage.setItem('was-host', 'true');
      return;
    }

    let wasHostItem: boolean | string | null = localStorage.getItem('was-host');
    if (wasHostItem) {
      wasHostItem = wasHostItem === 'true';
      setWasHost(wasHostItem);
    }
  }, [wasHost]);

  useEffect(() => {
    const lastCallData = localStorage.getItem('last-call-data');

    if (lastCallData) {
      setLastCall(JSON.parse(lastCallData));
    }
  }, []);

  useEffect(() => {
    if (lastCall) {
      localStorage.setItem('last-call-data', JSON.stringify(lastCall));
    }
  }, [lastCall]);

  return (
    <StateContext.Provider
      value={{
        api,
        lastCall,
        wasHost,
        isEnteringRoom,
        joinSession,
        quitSession,
        activeSessionData,
      }}
    >
      {props.children}
    </StateContext.Provider>
  );
}
