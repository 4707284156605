import { useCallback, useEffect, useState } from 'react';
import { RemoteParticipant, RemoteTrack, RemoteVideoTrack } from 'twilio-video';
import useVideoContext from '../video/useVideoContext';

export function useDominantSpeaker() {
  const { room } = useVideoContext();
  const [dominantSpeaker, setDominantSpeaker] = useState(room.dominantSpeaker);

  useEffect(() => {
    // Sometimes, the 'dominantSpeakerChanged' event can emit 'null', which means that
    // there is no dominant speaker. If we change the main participant when 'null' is
    // emitted, the effect can be jarring to the user. Here we ignore any 'null' values
    // and continue to display the previous dominant speaker as the main participant.
    const handleDominantSpeakerChanged = (newDominantSpeaker: RemoteParticipant) => {
      if (newDominantSpeaker !== null) {
        setDominantSpeaker(newDominantSpeaker);
      }
    };

    // Since 'null' values are ignored, we will need to listen for the 'participantDisconnected'
    // event, so we can set the dominantSpeaker to 'null' when they disconnect.
    const handleParticipantDisconnected = (participant: RemoteParticipant) => {
      setDominantSpeaker((prevDominantSpeaker) => {
        return prevDominantSpeaker === participant ? null : prevDominantSpeaker;
      });
    };

    room.on('dominantSpeakerChanged', handleDominantSpeakerChanged);
    room.on('participantDisconnected', handleParticipantDisconnected);
    return () => {
      room.off('dominantSpeakerChanged', handleDominantSpeakerChanged);
      room.off('participantDisconnected', handleParticipantDisconnected);
    };
  }, [room]);

  return dominantSpeaker;
}

export function useSelectedTrack() {
  const [selectedTrack, setSelectedTrack] = useState<RemoteVideoTrack | null>(null);

  useEffect(() => {
    if (!selectedTrack) {
      return;
    }

    const unsetTrack = () => setSelectedTrack(null);

    selectedTrack.on('unsubscribed', unsetTrack);

    return () => {
      selectedTrack.off('unsubscribed', unsetTrack);
    };
  }, [selectedTrack]);

  return {
    setSelectedTrack,
    selectedTrack,
  };
}

export type RemoteTrackList = { participant: RemoteParticipant; track: RemoteTrack }[];
export function useAllRemoteTracks() {
  const [tracks, setTracks] = useState<RemoteTrackList>([]);
  const { room } = useVideoContext();

  const updateTracks = function () {
    const participants = room.participants ? Array.from(room.participants.values()) : [];
    const remoteTracks: RemoteTrackList = [];

    for (const participant of participants) {
      const participantTracks = Array.from(participant.tracks.values())
        .filter((item) => item.track)
        .map((publication) => publication.track) as RemoteTrack[];

      remoteTracks.push(
        ...participantTracks.map((track) => {
          return { participant, track };
        })
      );
    }

    setTracks(remoteTracks);
  };

  useEffect(() => {
    room.on('trackSubscribed', updateTracks);
    room.on('trackUnsubscribed', updateTracks);

    return () => {
      room.off('trackSubscribed', updateTracks);
      room.off('trackUnsubscribed', updateTracks);
    };
  }, [room]);

  useEffect(() => {
    updateTracks();
  }, []);

  return tracks;
}

export function useLocalStatsReporter() {
  const { room } = useVideoContext();

  return useCallback(
    () => {
      const videoStats = room.localParticipant?.networkQualityStats?.video?.sendStats?.bandwidth;

      if (videoStats) {
        return {
          video_bandwidth_actual: videoStats.actual,
          video_bandwidth_available: videoStats.available,
          video_network_quality: videoStats.level,
          timestamp: Math.floor(Date.now() / 1000)
        };
      } else {
        return null;
      }
    },
    [room.localParticipant]
  );
}
