import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import { darken } from 'polished';
import { useHistory } from 'react-router-dom';

function UploadNotification() {
  const history = useHistory();

  return (
    <Container onClick={() => history.push('/uploader')}>
      <FontAwesomeIcon icon="exclamation-circle" />
      <p>please do not close the app. Your recording is still uploading...</p>
    </Container>
  );
}

export default UploadNotification;

const Container = styled.div`
  position: absolute;
  top: 25px;
  left: 25px;
  display: flex;
  align-items: center;
  border-radius: 28px;
  background-color: #f2dfc3;
  cursor: pointer;
  user-select: none;
  text-decoration: none;

  svg {
    color: #d0891e;
    font-size: 12px;
    font-weight: 400;
    text-transform: uppercase;
    margin: 0px 7px 0px 13px;
  }

  p {
    margin: 0px;
    color: #d0891e;
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
    padding: 6px 13px 6px 0px;
  }

  &:hover {
    background-color: ${() => darken(0.05, '#f2dfc3')};
  }
`;
