import React, { createContext, MutableRefObject, ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { CreateLocalTrackOptions, ConnectOptions, LocalAudioTrack, LocalVideoTrack, Room, TwilioError, Participant, LocalDataTrack } from 'twilio-video';
import logger from '../../../util/logger';

import {
  ScreenShareTracks,
  selectedParticipantContext,
  useDefaultDevices,
  useHandleOnDisconnect,
  useHandleRoomDisconnectionErrors,
  useHandleTrackPublicationFailed,
  useRoom,
  useLocalTracks
} from './internal/hooks';
import { toast } from 'react-toastify';

export type Callback = (...args: any[]) => void;
export type ErrorCallback = (error: TwilioError) => void;

export interface IVideoContext {
  room: Room;
  localTracks: (LocalAudioTrack | LocalVideoTrack | LocalDataTrack)[];
  isConnecting: boolean;
  connect: (token: string, roomName: string) => Promise<void>;
  onError: ErrorCallback;
  onDisconnect: Callback;
  getLocalVideoTrack: (newOptions?: CreateLocalTrackOptions) => Promise<LocalVideoTrack>;
  getLocalAudioTrack: (deviceId?: string) => Promise<LocalAudioTrack>;
  getLocalScreenTracks: () => Promise<ScreenShareTracks>;
  onLeaveRoomRef: MutableRefObject<() => void>;
  showPermissionPage: boolean;
  togglePermissionPage: (toggle: boolean) => void;
  audioOutput: { deviceId: string; setDevice: (deviceId: string) => void }
}

export const VideoContext = createContext<IVideoContext>(null!);

interface VideoProviderProps {
  options?: ConnectOptions;
  onError: ErrorCallback;
  onDisconnect?: Callback;
  children: ReactNode;
}

export function VideoProvider({ options, children, onError = () => undefined, onDisconnect = () => undefined }: VideoProviderProps) {
  const onErrorCallback = (error: TwilioError) => {
    logger.error(error);
    onError(error);
  };

  const { localTracks, getLocalVideoTrack, getLocalAudioTrack, getLocalScreenTracks } = useLocalTracks();
  const { room, isConnecting, connect } = useRoom(localTracks, onErrorCallback, options);
  const { getDefaultDevices } = useDefaultDevices();
  const [showPermissionPage, setPermissionPage] = useState(false);

  // set the default audio / video devices. Since the user can use the app many times we don't want
  // to make him change his preferred devices every time. Instead we will try to use the device
  // from the last call. If none is available (first call or the device is disconnected)
  // then we will select camera based on the highest resolution and use default audio device
  // (usually set by operating system preferences)
  const getDefaultTracks = useCallback(async () => {
    const defaultDevices = getDefaultDevices();

    try {
      await getLocalAudioTrack(defaultDevices.audioDeviceId);
    } catch (e) {
      if (e.name == 'NotAllowedError' || e.name == 'PermissionDeniedError') {
        setPermissionPage(true);
        return;
      } else if (e.name == 'OverconstrainedError' || e.name == 'ConstraintNotSatisfiedError') {
        logger.info('Got OverconstrainedError on audio device. The default device no longer exists. Selecting any other random audio device.');
        await getLocalAudioTrack();
      } else if (e.name == 'NotReadableError' || e.name == 'TrackStartError') {
        toast.error('Could not get microphone. Please check if your microphone is in use by any other tab or application.');
        logger.info('Device not readable');
      } else {
        throw e;
      }
    }

    const options = {};
    if (defaultDevices.videoDeviceId) {
      Object.assign(options, { deviceId: { exact: defaultDevices.videoDeviceId } });
    }

    try {
      await getLocalVideoTrack(options);
    } catch (e) {
      if (e.name == 'NotAllowedError' || e.name == 'PermissionDeniedError') {
        setPermissionPage(true);
        return;
      } else if (e.name == 'OverconstrainedError' || e.name == 'ConstraintNotSatisfiedError') {
        // in case that default device no longer exists just get some other available device
        logger.info('Got OverconstrainedError on video device. The default device no longer exists. Selecting any other random video device.');
        await getLocalVideoTrack();
      } else if (e.name == 'NotReadableError' || e.name == 'TrackStartError') {
        toast.error('Could not get camera. Please check if your camera is in use by any other tab or application.');
        logger.info('Device not readable');
      } else {
        throw e;
      }
    }
  }, []);

  useEffect(() => {
    getDefaultTracks().then();
  }, []);

  // stop all tracks on exit. This way we prevent webcam light from showing
  // active when not in call
  const onLeaveCallRoom = useCallback(() => {
    localTracks.forEach((item) => {
      if (item.kind === 'video' || item.kind === 'audio') {
        item.stop();
      }
    });
  }, [localTracks]);

  const onLeaveRoomRef = useRef(onLeaveCallRoom);

  useEffect(() => {
    onLeaveRoomRef.current = onLeaveCallRoom;
  }, [onLeaveCallRoom]);

  // Register onError and onDisconnect callback functions.
  useHandleRoomDisconnectionErrors(room, onError);
  useHandleTrackPublicationFailed(room, onError);
  useHandleOnDisconnect(room, onDisconnect);

  const audioOutput = useOutputDevice()

  return (
    <VideoContext.Provider
      value={{
        room,
        localTracks,
        isConnecting,
        onError: onErrorCallback,
        onDisconnect,
        getLocalVideoTrack,
        getLocalAudioTrack,
        getLocalScreenTracks,
        connect,
        onLeaveRoomRef,
        showPermissionPage,
        togglePermissionPage: setPermissionPage,
        audioOutput
      }}
    >
      <SelectedParticipantProvider room={room}>{children}</SelectedParticipantProvider>
    </VideoContext.Provider>
  );
}

type SelectedParticipantProviderProps = {
  room: Room;
  children: React.ReactNode;
};

export function SelectedParticipantProvider({ room, children }: SelectedParticipantProviderProps) {
  const [selectedParticipant, _setSelectedParticipant] = useState<Participant | null>(null);

  const setSelectedParticipant = (participant: Participant) =>
    _setSelectedParticipant((prevParticipant: any) => (prevParticipant === participant ? null : participant));

  useEffect(() => {
    const onDisconnect = () => _setSelectedParticipant(null);
    room.on('disconnected', onDisconnect);
    return () => {
      room.off('disconnected', onDisconnect);
    };
  }, [room]);

  return <selectedParticipantContext.Provider value={[selectedParticipant, setSelectedParticipant]}>{children}</selectedParticipantContext.Provider>;
}

function useOutputDevice() {
  const [deviceId, setDevice] = useState('default');
  return {deviceId, setDevice}
}
