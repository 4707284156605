import './fontawesome';
import '@fortawesome/fontawesome-svg-core/styles.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-tippy/dist/tippy.css';
import * as Sentry from "@sentry/react";

import React, { Fragment } from 'react';
import { AppStateProvider } from './hooks/useAppState';
import { UploaderProvider } from './hooks/useUploader';
import { ToastContainer } from 'react-toastify';
import Routes from './Routes';
import { PlatformProvider, usePlatform } from './hooks/platform/usePlatform';
import GlobalStyle from './GlobalStyle';
import { BrowserRouter } from 'react-router-dom';

function App(props: any) {
  return (
    <Fragment>
      <PlatformProvider platformRecorder={props.platformRecorder} platformUploader={props.platformUploader} platformHelpers={props.platformHelpers}>
        <AppRenderer appVersion={props.appVersion} />
      </PlatformProvider>
    </Fragment>
  );
}

export default Sentry.withProfiler(App)

function AppRenderer(props: any) {
  const { platformHelpers } = usePlatform();
  const Router = platformHelpers.router as typeof BrowserRouter;

  return (
    <Router>
      <AppStateProvider appVersion={props.appVersion}>
        <UploaderProvider>
          <GlobalStyle />
          <div>
            <ToastContainer />
            <Routes />
          </div>
        </UploaderProvider>
      </AppStateProvider>
    </Router>
  );
}
