import axios, { AxiosError, AxiosInstance, AxiosTransformer } from 'axios';
// @ts-ignore
import humps from 'humps';
import logger from '../util/logger';
import { RecordingType } from './platform/PlatformRecorder';

let BASE_URL = 'https://api.getwelder.com/v1';

if (process.env.WELDER_BUILD_ENVIRONMENT === 'prerelease' || process.env.NODE_ENV === 'development') {
  BASE_URL = 'https://api.staging.getwelder.com/v1';

  if (process.env.API_BASE_URL || process.env.REACT_APP_API_BASE_URL) {
    BASE_URL = (process.env.API_BASE_URL || process.env.REACT_APP_API_BASE_URL) as string;
  }
}

export default class API {
  axios: AxiosInstance;

  constructor() {
    this.axios = axios.create({
      baseURL: BASE_URL,
      headers: {},
      transformRequest: [
        (data) => {
          data = humps.decamelizeKeys(data);
          return data;
        },
        ...(axios.defaults.transformRequest as AxiosTransformer[]),
      ],
      transformResponse: [
        ...(axios.defaults.transformResponse as AxiosTransformer[]),
        (data) => {
          return humps.camelizeKeys(data);
        },
      ],
    });

    const LOG_BLACKLIST_URLS = ['/constellation/storage-files', 'constellation/storage-file-chunks/', 'storage.googleapis.com', '/calls/participants/?session_id', '/call-quality-logs/'];

    this.axios.interceptors.request.use((request) => {
      for (const item of LOG_BLACKLIST_URLS) {
        if (request.url && request.url.includes(item)) {
          return request;
        }
      }

      const log = {
        url: request.url,
        method: request.method,
        headers: request.headers,
        data: request.data,
      };

      logger.info('Starting API request:', log);
      return request;
    });

    this.axios.interceptors.response.use(
      (response) => {
        for (const item of LOG_BLACKLIST_URLS) {
          if (response.config.url && response.config.url.includes(item)) {
            return response;
          }
        }

        const log = {
          url: response.config.url,
          method: response.config.method,
          status: response.status,
          headers: response.headers,
          data: response.data,
        };

        logger.info('Got response from API:', log);
        return response;
      },
      (error: AxiosError) => {
        const log = {
          url: error.config?.url,
          method: error.config?.method,
          headers: error.config?.headers,
          status: error.response?.status,
          body: error.config?.data,
          data: error.response?.data,
        };

        logger.error('API response error: ', log);
        return Promise.reject(error);
      }
    );
  }

  async getSessionBySlug(sessionSlug: string) {
    return this.axios.get(`/calls/session-from-slug?session_slug=${sessionSlug}`).then((res) => res.data);
  }

  async getSession(id: string) {
    return this.axios.get(`/calls/sessions/${id}`).then((res) => res.data);
  }

  async getTwilioAccessInfo(participantId: string) {
    return this.axios.get(`/calls/participants/${participantId}/twilio-access-info`).then(res => res.data);
  }

  async updateSession(id: string, data: any) {
    return this.axios.patch(`/calls/sessions/${id}`, data).then((res) => res.data);
  }

  async getSessionParticipants(sessionId: string, hostToken?: string) {
    const response = await this.axios.get(`/calls/participants/?session_id=${sessionId}&host_token=${hostToken}`)
    return response.data
  }

  async getSessionParticipant(participantId: string) {
    const response = await this.axios.get(`/calls/participants/${participantId}`)
    return response.data
  }

  async updateLocalParticipantStats(participantId: string, stats: any) {
    return this.axios.post(`/calls/participants/${participantId}/call-quality-logs/`, stats).then(res => res.data)
  }

  async createSessionParticipant(sessionId: string, hostToken: string | null, clientType: 'WEB' | 'DESKTOP', browserInfo: any, machine: any) {
    return this.axios
      .post(`/calls/participants/`, {
        sessionId,
        hostToken,
        clientType,
        clientInfo: {
          browser: browserInfo,
          ...machine
        },
      })
      .then((res) => res.data);
  }

  async getOrCreateRecording(participantId: string, recordingType: RecordingType, startedAt: string): Promise<IRecordingAPIResponse> {
    const resp: any = await this.axios.post(`/calls/recordings/`, {
      participantId,
      recordingType,
      state: 'UPLOADING',
      startedAt
    });
    resp.data.justCreated = resp.status == 201;
    resp.data.storageFile = await this.getStorageFile(resp.data.storageFileId);

    return resp.data;
  }

  async updateRecording(recordingId: string, uploadingDone: boolean) {
    const reqData: any = {};

    if (uploadingDone) {
      reqData.state = 'UPLOADED';
    }

    const resp = await this.axios.patch(`/calls/recordings/${recordingId}`, reqData);
    return resp.data;
  }

  async getStorageFile(storageFileId: string) {
    const resp = await this.axios.get(`/constellation/storage-files/${storageFileId}`);
    return resp.data;
  }

  async getStorageFileChunkUrl(storageFileId: string, index: number, sizeBytes: number) {
    return this.axios
      .post('constellation/storage-file-chunks/', {
        storageFileId,
        index,
        sizeBytes,
      })
      .then((res) => res.data);
  }

  async uploadStorageFileChunk(blob: ArrayBuffer, url: string) {
    return axios
      .put(url, blob, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) => res.data);
  }

  createTimestamp(participantId: string, sessionRecordingId: string, startTimestamp: number, endTimestamp: number) {
    return this.axios.post('/calls/timestamps/', {
      participantId,
      sessionRecordingId,
      start: startTimestamp,
      end: endTimestamp
    }).then(res => res.data);
  }

  async storageChunkUploadDone(chunkId: string) {
    return this.axios
      .patch(`constellation/storage-file-chunks/${chunkId}`, {
        state: 'UPLOADED',
      })
      .then((res) => res.data);
  }

  async finishWebRecording(storageFileId: string) {
    return this.axios
      .patch(`constellation/storage-files/${storageFileId}`, {
        state: 'UPLOADED',
      })
      .then((res) => res.data);
  }
}

interface IStorageFile {
  id: string;
  totalSizeBytes: number;
  uploadedSizeBytes: number;
  state: string;
}

export interface IRecordingAPIResponse {
  id: string;
  sessionId: string;
  sessionRecordingId: string;
  participantId: string;
  storageFileId: string;
  state: string;
  justCreated: string;
  storageFile: IStorageFile;
}

export const api = new API();
