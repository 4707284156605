import React from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import { Tooltip } from 'react-tippy';
import { RingSpinner } from 'react-spinners-kit';

interface MenuItemProps {
  icon: IconProp;
  tooltip?: string;
  onClick: () => void;
  disabled?: boolean;
  loading?: boolean;
  id?: string;
}

function MenuItem(props: MenuItemProps) {
  return (
    <Tooltip distance={15} position="top" title={props.tooltip} theme="light">
      <div>
        <Container id={props.id} disabled={props.disabled} onClick={() => props.onClick()}>
          {props.loading ? <RingSpinner color="black" size={15} /> : <FontAwesomeIcon icon={props.icon} />}
        </Container>
      </div>
    </Tooltip>
  );
}

export default MenuItem;

interface ContainerProps {
  disabled?: boolean;
}

const Container = styled.div<ContainerProps>`
  width: 47px;
  height: 47px;
  border-radius: 16px;
  background-color: rgba(255, 255, 255, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  color: #000000;
  font-size: 15px;
  font-weight: 400;
  line-height: 15px;

  &:hover {
    background-color: rgba(255, 255, 255, 0.9);
  }

  ${(props) =>
    props.disabled &&
    `
    pointer-events: none;
    background-color: rgba(255, 255, 255, 0.4);
  `}
`;
