import { Participant as IParticipant } from 'twilio-video';
import { useParticipants } from '../hooks/participants/provider';
import { useRecorder } from '../hooks/recording/useRecorder';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import styled from 'styled-components';

interface ParticipantUploadStatusProps {
  participant: IParticipant;
  style?: React.CSSProperties;
}

export default function ParticipantUploadStatus(props: ParticipantUploadStatusProps) {
  const { isRecording } = useRecorder();
  const { getParticipantInfo } = useParticipants()
  const info = getParticipantInfo(props.participant);

  if (!info) {
    return null;
  }

  let text = isRecording? 'Uploading...' : 'Not recording';

  if (!info.uploadProgress.finished && info.uploadProgress.totalSizeBytes > 0) {
    let uploadedMB = Math.floor(info.uploadProgress.uploadedSizeBytes / 1000 / 1000)
    let totalMB = Math.floor(info.uploadProgress.totalSizeBytes / 1000 / 1000)
    text = `${uploadedMB}/${totalMB}MB Uploaded`
  } else if (info.uploadProgress.finished && info.uploadProgress.totalSizeBytes > 0) {
    text = 'Uploaded';
  }

  return (
    <Uploading style={props.style}>
      <FontAwesomeIcon icon='cloud-upload-alt' />
      <p>{text}</p>
    </Uploading>
  )
}

const Uploading = styled.div`
  background: rgba(0, 0, 0, 0.3);
  border-radius: 28px;
  display: flex;
  align-items: center;
  padding: 8.5px 12px;
  position: absolute;
  bottom: 10px;
  right: 10px;

  p {
    margin: 0;
  }

  svg {
    margin-right: 6px;
  }

  p, svg {
    font-weight: bold;
    font-size: 12px;
    line-height: 17px;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF;
  }
`
