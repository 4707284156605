import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { useAppState } from '../useAppState';
import { ParticipantInfo, useSession } from '../useSession';
import useVideoContext from '../video/useVideoContext';
import { Participant, RemoteParticipant } from 'twilio-video';
import { useLocalStatsReporter } from './participant';
import useSound from 'use-sound';

export function useParticipants() {
  const context = useContext(ParticipantsContext);
  if (!context) {
    throw new Error('useParticipants must be used within the ParticipantsProvider');
  }
  return context;
}

export type ParticipantsInfo = Map<string, ParticipantInfo>;
interface ParticipantsContextType {
  participantsInfo: ParticipantsInfo;
  participants: RemoteParticipant[];
  getParticipantInfo: (participant: Participant) => ParticipantInfo | undefined;
}

export const ParticipantsContext = createContext<ParticipantsContextType>(null!);

export function ParticipantsProvider(props: React.PropsWithChildren<any>) {
  const participants = useRemoteParticipants();
  const { room } = useVideoContext();
  const { api } = useAppState();
  const session = useSession();
  const [participantsInfo, setParticipantsInfo] = useState<ParticipantsInfo>(new Map());

  const [playPop] = useSound(require('../../assets/sounds/pop_welder.mp3'));
  const [playOut] = useSound(require('../../assets/sounds/out_welder.mp3'));

  const getLocalStats = useLocalStatsReporter();

  // Play sound when participants enters or leaves
  useEffect(() => {
    room.on('participantConnected', () => playPop());
    room.on('participantDisconnected', () => playOut());

    return () => {
      room.off('participantConnected', () => playPop());
      room.off('participantDisconnected', () => playOut());
    };
  }, [room]);

  // upload stats to server
  useEffect(() => {
    const interval = setInterval(() => {
      const stats = getLocalStats();
      if (stats) {
        api.updateLocalParticipantStats(session.participantInfo.id, stats);
      }
    }, 4000);

    return () => {
      clearInterval(interval);
    };
  }, [getLocalStats, api]);

  useEffect(() => {
    async function getParticipantsInfo() {
      let participants = await api.getSessionParticipants(session.sessionInfo.id, session.hostToken!);
      participants = participants.map((item: any) => [item.id, item]);
      setParticipantsInfo(new Map(participants));
    }

    let interval: NodeJS.Timeout | null = null;

    if (session.isHost) {
      getParticipantsInfo();

      interval = setInterval(() => {
        getParticipantsInfo();
      }, 3000);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [session]);

  const getParticipantInfo = useCallback(
    (participant: Participant) => {
      return participantsInfo.get(participant.identity) as ParticipantInfo | undefined;
    },
    [participantsInfo]
  );

  return (
    <ParticipantsContext.Provider
      value={{
        participants,
        participantsInfo,
        getParticipantInfo,
      }}
    >
      {props.children}
    </ParticipantsContext.Provider>
  );
}

function useRemoteParticipants() {
  const { room } = useVideoContext();
  const [participants, setParticipants] = useState(room.participants ? Array.from(room.participants.values()) : []);

  useEffect(() => {
    if (room.participants) {
      setParticipants(Array.from(room.participants.values()));
    }
  }, [room.participants]);

  useEffect(() => {
    const participantConnected = (participant: RemoteParticipant) => setParticipants((prevParticipants) => [...prevParticipants, participant]);
    const participantDisconnected = (participant: RemoteParticipant) =>
      setParticipants((prevParticipants) => prevParticipants.filter((p) => p !== participant));

    room.on('participantConnected', participantConnected);
    room.on('participantDisconnected', participantDisconnected);

    return () => {
      room.off('participantConnected', participantConnected);
      room.off('participantDisconnected', participantDisconnected);
    };
  }, [room]);

  return participants;
}
