import { createContext, useCallback, useEffect, useRef, useState } from 'react';

import Twilio, {
  ConnectOptions,
  CreateLocalTrackOptions,
  LocalAudioTrack,
  LocalDataTrack,
  LocalTrack,
  LocalVideoTrack,
  Participant,
  Room,
  TwilioError
} from 'twilio-video';

import EventEmitter from 'events';
import { ensureMediaPermissions } from '../../../../util/app';
import logger from '../../../../util/logger';
import { useSession } from '../../useSession';
import { toast } from 'react-toastify';
import Sentry from '../../../../util/sentry';

export type Callback = (...args: any[]) => void;

export function useLocalDataTrack() {
  const [track, setTrack] = useState<LocalDataTrack>();

  useEffect(() => {
    setTrack(new LocalDataTrack());
  }, []);

  // @ts-ignore
  useEffect(() => {
    const handleStopped = () => setTrack(new LocalDataTrack());

    if (track) {
      track.on('stopped', handleStopped);
      return () => {
        track.off('stopped', handleStopped);
      };
    }
  }, [track]);

  return track;
}

export type ScreenShareTracks = { screenTrack: Twilio.LocalVideoTrack; audioTrack?: Twilio.LocalAudioTrack };
export function useLocalScreenShareTrack() {
  const [track, setTrack] = useState<LocalVideoTrack>();
  const [audioTrack, setAudioTrack] = useState<LocalAudioTrack>();

  const getLocalScreenTracks = useCallback(async (): Promise<ScreenShareTracks> => {
    try {
      const stream = await (navigator.mediaDevices as any).getDisplayMedia({ video: true, audio: true });

      const screenTrack = new Twilio.LocalVideoTrack(stream.getVideoTracks()[0], {
        name: `screenshare-${stream.id}`,
        logLevel: 'info',
      });

      setTrack(screenTrack);

      const streamAudioTrack: MediaStreamTrack | undefined = stream.getAudioTracks().shift();
      if (streamAudioTrack) {
        const audioTrack = new Twilio.LocalAudioTrack(streamAudioTrack, {
          name: `screenshare-audio-${stream.id}`,
          logLevel: 'info',
        });
        setAudioTrack(audioTrack);

        return { screenTrack, audioTrack };
      }

      return { screenTrack };
    } catch (e) {
      if (e.name == 'NotAllowedError' || e.name == 'PermissionDeniedError') {
        logger.info('Permission denied for screenshare. User probably clicked on cancel button');
        throw e;
      }

      Sentry.captureException(e);
      logger.error(e);
      toast.error('Could not start screen sharing');
      throw e;
    }
  }, []);

  useEffect(() => {
    const handleStopped = () => setTrack(undefined);

    if (track) {
      track.on('stopped', handleStopped);
      return () => {
        track.off('stopped', handleStopped);
      };
    }

    return;
  }, [track]);

  return { screenTrack: track, screenAudioTrack: audioTrack, getLocalScreenTracks: getLocalScreenTracks };
}

export function useLocalAudioTrack() {
  const [track, setTrack] = useState<LocalAudioTrack>();
  const session = useSession();

  const getLocalAudioTrack = useCallback((deviceId?: string | null) => {
    const options: CreateLocalTrackOptions = {};

    if (deviceId) {
      options.deviceId = { exact: deviceId };
    }

    const postProcessingStatus = !!session.sessionInfo.settings.enableAudioPostProcessing;
    options.echoCancellation = postProcessingStatus;
    options.noiseSuppression = postProcessingStatus;
    options.autoGainControl = postProcessingStatus;
    options.name = `microphone-${Date.now()}`;

    return ensureMediaPermissions().then(() =>
      Twilio.createLocalAudioTrack(options)
        .then((newTrack) => {
          setTrack(newTrack);
          return newTrack;
        })
        .catch((e) => {
          if (e.name == 'NotReadableError' || e.name == 'TrackStartError') {
            toast.error('Could not get microphone. Please check if your microphone is in use by any other tab or application.');
            logger.info('Device not readable');
          }

          throw e;
        })
    );
  }, []);

  // @ts-ignore
  useEffect(() => {
    const handleStopped = () => setTrack(undefined);
    if (track) {
      track.on('stopped', handleStopped);
      return () => {
        track.off('stopped', handleStopped);
      };
    }
  }, [track]);

  return { audioTrack: track, getLocalAudioTrack };
}

export function useLocalVideoTrack() {
  const [track, setTrack] = useState<LocalVideoTrack>();

  const getLocalVideoTrack = useCallback((newOptions?: CreateLocalTrackOptions) => {
    // In the DeviceSelector and FlipCameraButton components, a new video track is created,
    // then the old track is unpublished and the new track is published. Unpublishing the old
    // track and publishing the new track at the same time sometimes causes a conflict when the
    // track name is 'camera', so here we append a timestamp to the track name to avoid the
    // conflict.
    const options: CreateLocalTrackOptions = {
      frameRate: 60,
      height: 4096,
      width: 2160,
      name: `camera-${Date.now()}`,
      resizeMode: 'none',
      ...newOptions,
    };

    return ensureMediaPermissions().then(() =>
      Twilio.createLocalVideoTrack(options)
        .then((newTrack) => {
          setTrack(newTrack);
          return newTrack;
        })
        .catch((e) => {
          if (e.name == 'NotReadableError' || e.name == 'TrackStartError') {
            toast.error('Could not get camera. Please check if your camera is in use by any other tab or application.');
            logger.info('Device not readable');
          }

          throw e;
        })
    );
  }, []);

  // @ts-ignore
  useEffect(() => {
    const handleStopped = () => setTrack(undefined);

    if (track) {
      track.on('stopped', handleStopped);
      return () => {
        track.off('stopped', handleStopped);
      };
    }
  }, [track]);

  return { videoTrack: track, getLocalVideoTrack };
}

export function useLocalTracks() {
  const { setDefaultDevices } = useDefaultDevices();

  const { audioTrack, getLocalAudioTrack } = useLocalAudioTrack();
  const { videoTrack, getLocalVideoTrack } = useLocalVideoTrack();
  const { screenTrack, screenAudioTrack, getLocalScreenTracks } = useLocalScreenShareTrack();

  const localDataTrack = useLocalDataTrack();

  const localTracks = [audioTrack, videoTrack, localDataTrack, screenTrack, screenAudioTrack].filter((track) => track !== undefined) as (
    | LocalAudioTrack
    | LocalVideoTrack
    | LocalDataTrack
  )[];

  useEffect(() => {
    if (!videoTrack) {
      logger.info('No video input');
      return;
    }

    logger.info('New video input device selected', videoTrack.mediaStreamTrack.getSettings());
  }, [videoTrack]);

  useEffect(() => {
    if (!audioTrack) {
      logger.info('No audio input');
      return;
    }

    logger.info('New audio input device selected', audioTrack.mediaStreamTrack.getSettings());
  }, [audioTrack]);

  useEffect(() => {
    setDefaultDevices(audioTrack, videoTrack);
  }, [videoTrack, audioTrack]);

  return {
    localTracks,
    localDataTrack,
    getLocalVideoTrack,
    getLocalAudioTrack,
    getLocalScreenTracks,
  };
}

export function useRoom(localTracks: LocalTrack[], onError: Callback, options?: ConnectOptions) {
  // @ts-ignore
  const [room, setRoom] = useState<Room>(new EventEmitter() as Room);
  const [isConnecting, setIsConnecting] = useState(false);
  const localTracksRef = useRef<LocalTrack[]>([]);

  useEffect(() => {
    // It can take a moment for Video.connect to connect to a room. During this time, the user may have enabled or disabled their
    // local audio or video tracks. If this happens, we store the localTracks in this ref, so that they are correctly published
    // once the user is connected to the room.
    localTracksRef.current = localTracks;
  }, [localTracks]);

  const connect = useCallback(
    async (token, roomName) => {
      setIsConnecting(true);
      return Twilio.connect(token, {
        ...options,
        tracks: [],
        name: roomName,
        dominantSpeaker: true,
        preferredVideoCodecs: [{ codec: 'VP8', simulcast: true }],
        networkQuality: {
          local: 3,
          remote: 0
        },
        bandwidthProfile: {
          video: {
            mode: 'collaboration',
            trackSwitchOffMode: 'disabled',
            contentPreferencesMode: 'auto'
          },
        },
      }).then(
        (newRoom) => {
          setRoom(newRoom);
          const disconnect = () => newRoom.disconnect();

          newRoom.once('disconnected', () => {
            window.removeEventListener('beforeunload', disconnect);
          });

          localTracksRef.current.forEach((track) =>
            // Tracks can be supplied as arguments to the Video.connect() function and they will automatically be published.
            // However, tracks must be published manually in order to set the priority on them.
            // All video tracks are published with 'low' priority. This works because the video
            // track that is displayed in the 'MainParticipant' component will have it's priority
            // set to 'high' via track.setPriority()
            // @ts-ignore
            newRoom.localParticipant.publishTrack(track, {
              priority: track.kind === 'video' ? 'low' : 'standard',
            })
          );

          setIsConnecting(false);

          // Add a listener to disconnect from the room when a user closes their browser
          window.addEventListener('beforeunload', disconnect);
        },
        (error) => {
          onError(error);
          setIsConnecting(false);
        }
      );
    },
    [options, onError]
  );

  return { room, isConnecting, connect };
}

export function useHandleRoomDisconnectionErrors(room: Room, onError: Callback) {
  useEffect(() => {
    const onDisconnected = (_room: Room, error: TwilioError) => {
      if (error) {
        onError(error);
      }
    };

    room.on('disconnected', onDisconnected);
    return () => {
      room.off('disconnected', onDisconnected);
    };
  }, [room, onError]);
}

export function useHandleTrackPublicationFailed(room: Room, onError: Callback) {
  const { localParticipant } = room;
  // @ts-ignore
  useEffect(() => {
    if (localParticipant) {
      localParticipant.on('trackPublicationFailed', onError);
      return () => {
        localParticipant.off('trackPublicationFailed', onError);
      };
    }
  }, [localParticipant, onError]);
}

export function useHandleOnDisconnect(room: Room, onDisconnect: Callback) {
  useEffect(() => {
    room.on('disconnected', onDisconnect);
    return () => {
      room.off('disconnected', onDisconnect);
    };
  }, [room, onDisconnect]);
}

type selectedParticipantContextType = [Participant | null, (participant: Participant) => void];

export const selectedParticipantContext = createContext<selectedParticipantContextType>(null!);

export function useDefaultDevices() {
  const setDefaultDevices = useCallback((audioTrack?: LocalAudioTrack, videoTrack?: LocalVideoTrack) => {
    const videoId = videoTrack?.mediaStreamTrack.getSettings().deviceId;
    const audioId = audioTrack?.mediaStreamTrack.getSettings().deviceId;

    if (videoId) {
      localStorage.setItem('default-video-device-id', videoId);
    }

    if (audioId) {
      localStorage.setItem('default-audio-device-id', audioId);
    }
  }, []);

  const getDefaultDevices = useCallback(() => {
    return { videoDeviceId: localStorage.getItem('default-video-device-id'), audioDeviceId: localStorage.getItem('default-audio-device-id') };
  }, []);

  return { getDefaultDevices, setDefaultDevices };
}
