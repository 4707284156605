import useDataChannel from '../useDataChannel';
import useUploader from '../useUploader';
import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import logger from '../../../util/logger';
import moment from 'moment';
import { waitTill } from '../../../util/app';
import { useLocalAudioToggle, useLocalVideoToggle } from '../useVideoControls';
import { useAppState } from '../useAppState';
import { useHistory } from 'react-router';
import { useRecorder } from '../recording/useRecorder';
import { useSession } from '../useSession';
import { useScreenShare } from '../screen/useScreenShare';
import { useParticipants } from '../participants/provider';
import useVideoContext from '../video/useVideoContext';
import useRoomState from '../video/useRoomState';

export default function useCallControls() {
  const context = useContext(CallControlsContext);
  if (!context) {
    throw new Error('useCallControls must be used within the CallControlsProvider');
  }
  return context;
}

export type CallLayout = 'grid' | 'dominant';

interface CallControlsContextType {
  userOpen: boolean;
  deviceOpen: boolean;
  leaveOpen: boolean;
  isAudioEnabled: boolean;
  isVideoEnabled: boolean;
  isClosing: boolean;
  isHiddenUI: boolean;
  toggleUserOpen: (toggle: boolean) => void;
  toggleDeviceModal: (toggle: boolean) => void;
  toggleLeaveOpen: (toggle: boolean) => void;
  toggleAudioEnabled: () => void;
  toggleVideoEnabled: () => void;
  toggleUIHide: (toggle: boolean) => void;
  leaveRoom: (timestamp?: number) => void;
  callLayout: CallLayout;
  switchCallLayout: (newView: CallLayout) => void;
  isScreenSharing: boolean;
  isRemoteScreenSharing: boolean;
  toggleScreenShare: () => void;
}

export const CallControlsContext = createContext<CallControlsContextType>(null!);

export function CallControlsProvider(props: React.PropsWithChildren<any>) {
  const { participants } = useParticipants();
  const [userOpen, toggleUserOpen] = React.useState(false);
  const [deviceOpen, toggleDeviceModal] = React.useState(false);
  const [leaveOpen, toggleLeaveOpen] = React.useState(false);
  const [isAudioEnabled, toggleAudioEnabled] = useLocalAudioToggle();
  const [isVideoEnabled, toggleVideoEnabled] = useLocalVideoToggle();
  const history = useHistory();
  const roomState = useRoomState();
  const { room } = useVideoContext();
  const { isRecording, stopRecording, hasActiveRecording } = useRecorder();
  const dataChannel = useDataChannel();
  const uploader = useUploader();
  const [isClosing, setIsClosing] = useState(false);
  const [isHiddenUI, toggleUIHide] = useState(false);
  const session = useSession();
  const state = useAppState();
  const { isRemoteScreenSharing, isScreenSharing, startScreenshare, stopScreenshare } = useScreenShare();
  const [userSwitchedView, setUserSwitchedView] = useState(false);

  const [callLayout, setCallLayout] = useState<CallLayout>('grid');

  const leaveRoom = useCallback(
    async (timestamp?: number) => {
      setIsClosing(true);

      logger.info(`Leaving session ${session.sessionSlug}`);
      let redirectPath: string | null = '/';

      if (session.sessionInfo.sessionType !== 'MULTI_PLAYER') {
        logger.info('Leaving singpleplayer session...')
        if (isRecording && hasActiveRecording) {
          logger.info(`Stopping recording based on session leave`);
          await stopRecording();
        }

        if (hasActiveRecording) {
          state.api.updateSession(session.sessionInfo.id, {
            recordingEnabled: false,
            session: 'FINISHED',
          });

          await uploader.checkNewUploads();
          redirectPath = '/uploader';
        }

        history.push(redirectPath);
        return;
      }


      if (roomState === 'connected') {
        logger.info('Leaving multiplayer session...')
        const quitTimestamp = timestamp || moment().add(3, 'seconds').valueOf();

        if (session.isHost && !timestamp) {
          if (hasActiveRecording) {
            state.api.updateSession(session.sessionInfo.id, {
              recordingEnabled: false,
              session: 'FINISHED',
            });
          }

          dataChannel.broadcastMessage({
            type: 'host-leave-call',
            value: quitTimestamp,
          });
        }

        await waitTill(quitTimestamp);

        if (isRecording && hasActiveRecording) {
          logger.info(`Stopping recording based on session leave`);
          await stopRecording();
        }

        if (hasActiveRecording) {
          await uploader.checkNewUploads();
          redirectPath = '/uploader';
        } else {
          redirectPath = null;
        }

        room.disconnect();
      }

      setIsClosing(false);

      if (redirectPath) {
        history.push(redirectPath);
      }
    },
    [roomState, isRecording, hasActiveRecording, session.sessionSlug]
  );

  const switchCallLayout = useCallback(
    (newView: CallLayout, userAction = true) => {
      setCallLayout(newView);
      if (userAction) {
        setUserSwitchedView(true);
      }
    },
    [setCallLayout]
  );

  const toggleScreenShare = useCallback(async () => {
    if (isScreenSharing) {
      await stopScreenshare();
    } else {
      await startScreenshare();
    }
  }, [startScreenshare, stopScreenshare, isScreenSharing]);

  useEffect(() => {
    if (userSwitchedView) {
      return;
    }

    if (isRemoteScreenSharing && callLayout === 'grid') {
      switchCallLayout('dominant', false);
    }
  }, [isRemoteScreenSharing, callLayout, userSwitchedView, participants.length]);

  useEffect(() => {
    dataChannel.on('host-leave-call', leaveRoom);

    return () => {
      dataChannel.off('host-leave-call', leaveRoom);
    };
  }, [leaveRoom]);

  useEffect(() => {
    logger.info(`Switching to new call view: ${callLayout}`);
  }, [callLayout]);

  return (
    <CallControlsContext.Provider
      value={{
        userOpen,
        deviceOpen,
        leaveOpen,
        isAudioEnabled,
        isVideoEnabled,
        isClosing,
        isHiddenUI,
        toggleUserOpen,
        toggleDeviceModal,
        toggleLeaveOpen,
        toggleAudioEnabled,
        toggleVideoEnabled,
        leaveRoom,
        toggleUIHide,
        callLayout,
        switchCallLayout,
        isRemoteScreenSharing,
        isScreenSharing,
        toggleScreenShare,
      }}
    >
      {props.children}
    </CallControlsContext.Provider>
  );
}
