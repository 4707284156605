import React, { useEffect, useState } from 'react';
import { Switch, Route, useHistory } from 'react-router-dom';
import Home from './containers/Home';
import Call from './containers/Room';
import Uploader from './containers/Uploader';
import logger from '../util/logger';
import CallJoiner from './containers/CallJoiner';
import { detect, BrowserInfo } from 'detect-browser';
import { usePlatform } from './hooks/platform/usePlatform';
import GetChrome from './containers/GetChrome';

export default function Routes() {
  const history = useHistory();
  const [browser, setBrowser] = useState<BrowserInfo | null>(null);
  const { platformHelpers } = usePlatform();

  useEffect(() => {
    logger.info(`location changed: ${history.location.pathname}`);
  }, [history.location]);

  useEffect(() => {
    if (platformHelpers.platform === 'WEB') {
      const browser = detect() as BrowserInfo;
      setBrowser(browser);
    }
  }, []);

  if (browser?.name === 'safari' && window.location.pathname !== '/get-chrome') {
    window.location.pathname = '/get-chrome';
    return null;
  }

  return (
    <>
      <Switch>
        <Route exact path="/call/:slug" component={Call} />
        <Route exact path="/j" component={CallJoiner} />
        <Route exact path="/uploader" component={Uploader} />
        <Route exact path="/" component={Home} />
        <Route exact path="/get-chrome" component={GetChrome} />
      </Switch>
    </>
  );
}
