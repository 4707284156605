import React, { useState } from 'react';
import {useRecorder, useRecordingCounter} from '../hooks/recording/useRecorder';
import styled, { css } from 'styled-components';
// @ts-ignore
import Modal from 'react-modal';
import { Tooltip } from 'react-tippy';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { darken, lighten, transparentize } from "polished";
import { RingSpinner } from 'react-spinners-kit';
import { useSession } from '../hooks/useSession';
import { usePlatform } from '../hooks/platform/usePlatform';
import StopRecordingModal from './StopRecordingModal';
import { useTimestamps } from "../hooks/timestamps/useTimestamps";

function RecordingInfo() {
  const [leaveModalOpen, toggleLeaveModal] = useState(false);
  const session = useSession();
  const { createTimestamp } = useTimestamps()
  const { platformRecorder } = usePlatform();

  const { isRecording, isStopping, isTesting, safeRecordingStop } = useRecorder();
  let time = useRecordingCounter()

  if (isTesting) {
    time = `${time ?? '00:00:00'} / 00:00:10`
  }

  const buttons = () => {
    if (!session.isHost) {
      return null;
    }

    if (isRecording && !isTesting) {
      return (
        <RecordingContainer isDisabled={isStopping} onClick={() => toggleLeaveModal(true)}>
          {isStopping ? <RingSpinner color="black" size={17} /> : <FontAwesomeIcon icon="stop" />}
        </RecordingContainer>
      );
    }

    return null;
  };

  return (
    <Container isTesting={isTesting} smallContainer={!platformRecorder.showSize} isHost={session.isHost}>
      <Modal overlayClassName="Overlay" className="Modal" isOpen={leaveModalOpen} onRequestClose={() => toggleLeaveModal(false)}>
        <StopRecordingModal stopRecording={safeRecordingStop} toggleModal={toggleLeaveModal} />
      </Modal>

      {buttons()}

      <Item isHost={session.isHost} isTesting={isTesting}>
        <p>
          Recording: <b>{time}</b>
        </p>
        {(session.isHost && !isTesting) &&
          <Tooltip distance={15} position="top" title="Time stamp. [press “space bar”]" theme="light">
            <Timestamp onClick={() => createTimestamp()}>
              <Divider />
              <FontAwesomeIcon icon='tag' />
            </Timestamp>
          </Tooltip>
        }
      </Item>
    </Container>
  );
}

export default RecordingInfo;

interface Props {
  isHost?: boolean;
  isTesting?: boolean;
}

interface ContainerProps {
  smallContainer: boolean;
  isTesting: boolean;
}

const Container = styled.div<Props & ContainerProps>`
  box-shadow: 0 11px 41px rgba(50, 50, 50, 0.4);
  border-radius: 14px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  min-width: ${(props) => (props.smallContainer ? '230px' : '370px')}

  ${(props) =>
    !props.isHost &&
    `
    min-width: initial;
    padding-left: 6px;
    padding-right: 6px;
  `};

  ${(props) => props.isTesting && css`
    min-width: 245px;
  `}
`;

interface RecordingProps {
  isDisabled: boolean;
  isPrimary?: boolean;
}

const RecordingContainer = styled.div<RecordingProps>`
  border-radius: 14px;
  background-color: #d7dfe8;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #000000;

  > * {
    font-size: 15px;
    font-weight: 400;
  }

  &:hover {
    color: #e85959;
  }

  ${(props) =>
    props.isDisabled &&
    `
      background-color: ${transparentize(0.7, '#d7dfe8')};
      pointer-events: none;
  `}

  ${(props) =>
    props.isPrimary &&
    `
      background-color: #feca45;

      &:hover {
        background-color: ${lighten(0.05, '#feca45')};
      }

      ${
        props.isDisabled &&
        `
        background-color: ${transparentize(0.7, '#feca45')};
      `
      }
  `}
`;

const Item = styled.div<Props>`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  min-width: ${(props) => props.isHost ? '240px' : '220px'};
  padding: 0 0 0 20px;

  p {
    margin: 0 auto;
    font-size: 18px;
    color: #c4c4c4;
    margin-right: ${(props) => props.isHost ? '20px' : 'auto'};
    display: flex;
    align-items: center;

    ${(props) => props.isTesting && css`
      margin-left: 15px;
      margin-right: 15px;
    `}
  }

  b {
    color: #000000;
    margin-left: 4px;

    ${(props) => !props.isTesting && css`
      width: 78px;
      display: block;
    `}
  }

  ${(props) =>
    (!props.isHost || props.isTesting) &&
    `
    padding: 18px 7px;
  `}
`;

const Divider = styled.div`
  position: absolute;
  border: 1px solid #f5f5f5;
  height: 100%;
  left: 0px;
`

const Timestamp = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  width: 60px;
  cursor: pointer;

  svg {
    transform: rotate(45deg);
    color: #feca45;
    font-size: 16px;
    font-weight: 400;
    text-align: left;
  }

  &:hover {
    svg {
      color: ${() => darken(0.1, '#feca45')};
    }
  }
`
