import React from 'react';
import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { lighten, transparentize } from 'polished';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface ActionProps {
  text: string;
  icon?: IconProp;
  iconElement?: JSX.Element;
  onClick: () => void;
  disabled?: boolean;
  id?: string;
  type?: 'primary' | 'secondary' | 'return' | 'full-width';
  opacity?: number;
}

function ActionButton(props: ActionProps) {
  const type = props.type || 'primary';
  const opacity = 1 - (props.opacity || 1)

  let element = props.icon ? <FontAwesomeIcon icon={props.icon} /> : null
  if (props.iconElement) {
    element = props.iconElement
  }

  return (
    <Container id={props.id} buttonType={type} disabled={props.disabled} onClick={() => props.onClick()} opacity={opacity}>
      {element}
      <Text>{props.text}</Text>
    </Container>
  );
}

export default ActionButton;

interface ContainerProps {
  buttonType: 'primary' | 'secondary' | 'return' | 'full-width';
}

const Container = styled.button<ContainerProps>`
  box-shadow: 0 11px 41px rgba(188, 160, 89, 0.4);
  border-radius: 14px;
  background-image: linear-gradient(90deg, #ffd260 0%, #feca45 100%);
  color: #000000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 18px 20px;
  cursor: pointer;
  outline: none;
  border: none;
  appearance: none;
  user-select: none;

  svg {
    font-size: 15px;
    font-weight: 400;
    margin-right: 17px;
  }

  &:hover {
    background-image: linear-gradient(90deg, ${() => lighten(0.05, '#ffd260')} 0%, ${() => lighten(0.05, '#feca45')} 100%);
  }

  ${(props) =>
    props.buttonType === 'secondary' &&
    css`
      background-image: none;
      border: 2px solid ${props.opacity > 0 ? 'rgba(255, 255, 255, 0)' : '#d7dfe8'};
      background-color: ${transparentize(props.opacity, '#d7dfe8')};
      box-shadow: none;

      &:hover {
        background-image: none;
        background-color: ${lighten(0.02, transparentize(props.opacity, '#d7dfe8'))};
      }
    `}

  ${(props) =>
    props.buttonType === 'return' &&
    css`
      background-image: none;
      background-color: #000000;
      color: white;
      box-shadow: none;

      &:hover {
        background-image: none;
        background-color: ${lighten(0.1, '#000000')};
      }
    `}

  ${(props) => props.buttonType === 'full-width' &&
    css`
      width: 100%;
      flex-direction: row-reverse;
    `
  }
`;

const Text = styled.div`
  font-size: 18px;
  font-weight: 700;
  margin: 0;
`;
