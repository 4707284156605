import React from 'react';
import styled from 'styled-components';
import DominantItem from '../../../components/layout/DominantItem';
import SidebarContainer from '../../../components/layout/SidebarContainer';
import VideoTrack from '../../../components/VideoTrack';
import SidebarItem from '../../../components/layout/SidebarItem';
import Waiting from '../../../components/layout/Waiting';
import { useAllRemoteTracks, useDominantSpeaker, useSelectedTrack } from '../../../hooks/participants/participant';
import useVideoContext from '../../../hooks/video/useVideoContext';

function DominantLayout() {
  const {
    localTracks,
    room: { localParticipant, participants },
  } = useVideoContext();

  const dominantParticipant = useDominantSpeaker();
  const { selectedTrack, setSelectedTrack } = useSelectedTrack();

  const remoteTracks = useAllRemoteTracks();
  const videoFeeds = remoteTracks.filter((item) => item.track.kind === 'video');
  const dominantFeed =
    videoFeeds.find((feed) => feed.track === selectedTrack) ??
    videoFeeds.find((feed) => feed.track.name.includes('screenshare')) ??
    videoFeeds.find((feed) => feed.participant === dominantParticipant) ??
    videoFeeds.shift();

  const sidebarFeeds: JSX.Element[] = [];

  const localFeeds = localTracks
    .filter((item) => item.kind === 'video')
    .map((track) => {
      return (
        <SidebarItem track={track as any} key={track.name + localParticipant.sid} participant={localParticipant}>
          <VideoTrack key="local-feed" track={track} isLocal />
        </SidebarItem>
      );
    });

  sidebarFeeds.push(
    ...videoFeeds
      .filter((feed) => feed !== dominantFeed)
      .map((feed) => {
        return (
          <SidebarItem
            track={feed.track as any}
            setSelectedTrack={setSelectedTrack}
            selectedTrack={selectedTrack}
            key={feed.track.sid}
            participant={feed.participant}
          >
            <VideoTrack track={feed.track} priority="low" />
          </SidebarItem>
        );
      })
  );

  sidebarFeeds.push(...localFeeds);

  let dominantElement: JSX.Element | null = null;

  if (dominantFeed) {
    dominantElement = (
      <DominantItem setSelectedTrack={setSelectedTrack} selectedTrack={selectedTrack} track={dominantFeed.track as any} participant={dominantFeed.participant}>
        <VideoTrack track={dominantFeed.track} priority="high" />
      </DominantItem>
    );
  } else if (participants.size === 0) {
    dominantElement = <Waiting />;
  }

  return (
    <Container>
      <DominantContainer>{dominantElement}</DominantContainer>
      <SidebarContainer>{sidebarFeeds}</SidebarContainer>
    </Container>
  );
}

export default DominantLayout;

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  padding: 10px;
`;

const DominantContainer = styled.div`
  height: calc(100% - 120px);
  flex: 1;
  margin-right: 10px;
  overflow: hidden;
`;
