import React, { useCallback, useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RemoteParticipant, RemoteVideoTrack } from 'twilio-video';
import ParticipantUploadStatus from '../ParticipantUploadStatus';

interface DominantItemProps {
  children: React.ReactNode;
  track: RemoteVideoTrack;
  participant?: RemoteParticipant;
  selectedTrack: RemoteVideoTrack | null;
  setSelectedTrack: (track: RemoteVideoTrack | null) => void;
}

export default function DominantItem(props: DominantItemProps) {
  const [showUploadStatus, toggleUploadStatus] = useState(false);
  const [containerBounds, setContainerBounds] = useState<DOMRect | null>(null);
  const [dimensions, setDimensions] = useState<{width: null | number; height: null | number}>({width: null, height: null});
  const containerRef = useRef<HTMLDivElement>(null);

  const trackDimensions = props.track.dimensions;

  const updateContainerBounds = useCallback(() => {
    if (!containerRef.current) {
      return;
    }

    setContainerBounds(containerRef.current.getBoundingClientRect());
  }, []);

  useEffect(() => {
    window.addEventListener('resize', updateContainerBounds);

    return () => {
      window.removeEventListener('resize', updateContainerBounds);
    };
  }, [updateContainerBounds]);

  const getDimensions = useCallback(() => {
    if (!trackDimensions.width || !trackDimensions.height || !containerBounds) {
      return
    }

    const trackAspectRatio = trackDimensions.width / trackDimensions.height;

    let width: number;
    let height: number;

    const widthDifference = containerBounds.width / trackDimensions.width;
    const heightDifference = containerBounds.height / trackDimensions.height;

    if (widthDifference < heightDifference) {
      width = containerBounds.width;
      height = containerBounds.width / trackAspectRatio;
    } else {
      width = containerBounds.height * trackAspectRatio;
      height = containerBounds.height;
    }

    setDimensions({ width, height })
  }, [trackDimensions.height, trackDimensions.width, containerBounds]);

  useEffect(() => {
    getDimensions()
  }, [getDimensions])

  useEffect(() => {
    props.track.on('started', getDimensions);

    return () => {
      props.track.off('started', getDimensions)
    }
  }, [props.track, getDimensions])

  useEffect(() => {
    if (containerRef.current) {
      updateContainerBounds();
    }
  }, [containerRef.current]);

  let opacity = 0;

  if (dimensions.width) {
    opacity = 1
  }

  return (
    <Container ref={containerRef}>
      <TrackContainer onMouseEnter={() => toggleUploadStatus(true)} onMouseLeave={() => toggleUploadStatus(false)} style={{ opacity }} {...dimensions}>
        {props.children}
        {showUploadStatus &&
          <ParticipantUploadStatus participant={props.participant} style={{left: '10px', bottom: '10px', right: 'initial'}} />
        }
        {props.selectedTrack === props.track ? (
          <>
            <UnpinContainer onClick={() => props.setSelectedTrack(null)}>
              <FontAwesomeIcon icon="map-marker-slash" />
              <p>Unpin view</p>
            </UnpinContainer>
          </>

        ) : null}
      </TrackContainer>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: flex-end;
`;

const TrackContainer = styled.div<{ width?: number | null; height?: number | null }>`
  width: ${(props) => (props.width ? `${props.width}px` : '100%')};
  height: ${(props) => (props.height ? `${props.height}px` : '100%')};
  position: relative;
`;

const UnpinContainer = styled.div`
  display: flex;
  align-items: center;
  border-radius: 16px;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 16px 14px;
  position: absolute;
  right: 10px;
  bottom: 10px;
  cursor: pointer;
  transition: 0.1s background-color;

  p {
    margin: 0 auto;
    margin-left: 10px;
  }

  svg,
  p {
    color: #ffffff;
    font-size: 12px;
    font-weight: 700;
    font-style: normal;
    letter-spacing: normal;
    line-height: normal;
    text-align: right;
  }

  svg {
    font-size: 15px;
    font-weight: 400;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 1);
  }
`;
