import React, { createContext, useCallback, useContext, useEffect, useMemo } from 'react';
import { LocalAudioTrack, LocalVideoTrack } from 'twilio-video';
import { useAllRemoteTracks } from '../participants/participant';
import logger from '../../../util/logger';
import useVideoContext from '../video/useVideoContext';

export function useScreenShare() {
  const context = useContext(ScreenShareContext);

  if (!context) {
    throw new Error('useScreenShare must be used within the ScreenShareProvider');
  }
  return context;
}

interface ScreenShareContextType {
  isScreenSharing: boolean;
  screenShareTrack: LocalVideoTrack | undefined;
  isRemoteScreenSharing: boolean;
  startScreenshare: (publish?: boolean) => void;
  stopScreenshare: () => void;
}

export const ScreenShareContext = createContext<ScreenShareContextType>(null!);

export function ScreenShareProvider(props: React.PropsWithChildren<{}>) {
  const { localTracks, room, getLocalScreenTracks } = useVideoContext();

  const feeds = useAllRemoteTracks();

  const screenShareTrack = useMemo(() => {
    return localTracks.filter((track) => track.kind === 'video').find((track) => track.name.includes('screenshare')) as LocalVideoTrack | undefined;
  }, [localTracks]);

  const audioTrack = useMemo(() => {
    return localTracks.filter((track) => track.kind === 'audio').find((track) => track.name.includes('screenshare')) as LocalAudioTrack | undefined;
  }, [localTracks]);

  const isScreenSharing = !!screenShareTrack;

  const isRemoteScreenSharing = useMemo(() => {
    return !!feeds.map((feed) => feed.track).find((track) => track.kind === 'video' && track.name.includes('screenshare'));
  }, [feeds]);

  const startScreenshare = useCallback(async (publish = true) => {
    const tracks = await getLocalScreenTracks();

    if (publish) {
      await room.localParticipant.publishTracks([tracks.screenTrack, tracks.audioTrack].filter((item) => !!item) as []);
    }
  }, [room, getLocalScreenTracks]);

  const stopScreenshare = useCallback(async () => {
    if (!screenShareTrack) {
      logger.info('No screenshare track to stop...');
      return;
    }

    if (room.localParticipant) {
      let publication = room.localParticipant.unpublishTrack(screenShareTrack);
      // remove when SDK issue is fixed
      room.localParticipant.emit('trackUnpublished', publication);
    }
    
    screenShareTrack.stop();

    if (audioTrack) {

      if (room.localParticipant) {
        const publication = room.localParticipant.unpublishTrack(audioTrack);
        // remove when SDK issue is fixed
        room.localParticipant.emit('trackUnpublished', publication);
      }

      audioTrack.stop();
    }
  }, [screenShareTrack, audioTrack]);

  useEffect(() => {
    if (!screenShareTrack) {
      return;
    }

    const stopped = () => stopScreenshare();

    screenShareTrack.on('stopped', stopped);

    return () => {
      screenShareTrack.off('stopped', stopped);
    };
  }, [screenShareTrack, stopScreenshare]);

  useEffect(() => {
    logger.info('is screen sharing: ', isScreenSharing);
  }, [isScreenSharing]);

  return (
    <ScreenShareContext.Provider
      value={{
        isScreenSharing,
        screenShareTrack,
        isRemoteScreenSharing,
        startScreenshare,
        stopScreenshare,
      }}
    >
      {props.children}
    </ScreenShareContext.Provider>
  );
}
