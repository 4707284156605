import React from 'react';
import styled from 'styled-components';
import Headline from './ui/Headline';
import ActionButton from './ui/ActionButton';

interface ModalProps {
  stopRecording: () => void;
  toggleModal: (toggle: boolean) => void;
}

function StopRecordingModal(props: ModalProps) {
  return (
    <Container>
      <Headline text="Do you want to stop the recording for all participants?" />
      <Buttons>
        <ActionButton text="Keep recording" onClick={() => props.toggleModal(false)} type="secondary" />
        <ActionButton
          text="Stop recording"
          icon="stop"
          onClick={() => {
            props.toggleModal(false);
            props.stopRecording();
          }}
        />
      </Buttons>
    </Container>
  );
}

export default StopRecordingModal;

const Container = styled.div`
  max-width: 582px;
`;

const Buttons = styled.div`
  display: flex;

  > *:first-child {
    margin-right: 22px;
  }
`;

