// This function ensures that the user has granted the browser permission to use audio and video
// devices. If permission has not been granted, it will cause the browser to ask for permission
import { detect } from 'detect-browser';
import moment from 'moment';
import Sentry from '../sentry';
// @ts-ignore
import { v4 as uuidv4 } from 'uuid';

// for audio and video at the same time (as opposed to separate requests).
export function ensureMediaPermissions() {
  const browser = detect();
  if (browser?.name === 'firefox') {
    return Promise.resolve();
  }

  return (
    navigator.mediaDevices
      .enumerateDevices()
      .then((devices) => devices.every((device) => !(device.deviceId && device.label)))
      // @ts-ignore
      .then((shouldAskForMediaPermissions) => {
        if (shouldAskForMediaPermissions) {
          return navigator.mediaDevices
            .getUserMedia({
              audio: true,
              video: true,
            })
            .then((mediaStream) => mediaStream.getTracks().forEach((track) => track.stop()));
        }
      })
  );
}

export async function ensureStoragePermissions() {
  if (navigator.storage && navigator.storage.persist) {
    return navigator.storage.persist();
  }

  return false;
}

export function timeout(ms: number | undefined) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function waitTill(timestamp: number) {
  const diff = timestamp - moment().valueOf();
  return timeout(diff);
}

export function getMachineIds() {
  let machineId = localStorage.getItem('machine_id');
  let sessionId = sessionStorage.getItem('session_id');

  if (!machineId) {
    machineId = uuidv4() as string;
    localStorage.setItem('machine_id', machineId);
  }

  if (!sessionId) {
    sessionId = uuidv4() as string;
    sessionStorage.setItem('session_id', sessionId);
  }

  Sentry.setTags({ sessionId, machineId });

  return { machineId, sessionId };
}
