import "core-js/stable";
import "regenerator-runtime/runtime";

import React from 'react';
import ReactDOM from 'react-dom';
// @ts-ignore
import Modal from 'react-modal';
import WebRecorder from './platform/WebRecorder';
import WebUploader from './platform/WebUploader';
import WebHelpers from './platform/WebHelpers';
import App from './core/App';
import * as Sentry from '@sentry/react';
import logger from './util/logger';
import GlobalStyle from './core/GlobalStyle';
// If taking advantage of automatic instrumentation (highly recommended)
import { Integrations as TracingIntegrations } from "@sentry/tracing";
import { detect } from 'detect-browser';

let environment = 'staging';

if (process.env.WELDER_BUILD_ENVIRONMENT === 'production') {
  environment = 'production';
}

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://f146db0060dc4e6787bf2fc14c775687@o762950.ingest.sentry.io/5793695',
    environment: environment,
    integrations: [new TracingIntegrations.BrowserTracing()],
    tracesSampleRate: 0.2,
    release: process.env.GITHUB_RUN_NUMBER
  });
}

let shouldStartApp = true;

function init() {
  const LATEST_ALLOWED_CHROME_VERSION = 85
  const browser = detect();

  logger.info('Opened in: ', browser?.name)
  logger.info(browser);

  if (browser?.name === 'safari') {
    shouldStartApp = false
    logger.info('Opened in safari. Show get Chrome text.');
    unavailableOnSafari()
    return;
  } else if (browser?.name === 'chrome' && parseInt(browser.version.split('.')[0]) < LATEST_ALLOWED_CHROME_VERSION) {
    shouldStartApp = false
    logger.info(`Opened in old chrome (${browser.version}). Show update Chrome text.`);
    oldChromeVersion()
    return;
  }

  const channel = new BroadcastChannel('tab');
  channel.postMessage('created-tab');
  // note that listener is added after posting the message
  channel.addEventListener('message', (msg) => {
    if (msg.data === 'created-tab') {
      channel.postMessage('tab-already-active');
    }

    if (msg.data === 'tab-already-active') {
      // message received from 1st tab
      shouldStartApp = false;
      channel.close();
      logger.info('Has an already active tab. ShouldStartApp switch to false.');
      secondTabApp();
    }
  });

  if (window.innerWidth < 960) {
    shouldStartApp = false;
    unavailableOnMobile();
    logger.info('Opened on low screen width. Show mobile text.');
  }

  setTimeout(startApp, 1000);
}

function startApp() {
  if (!shouldStartApp) {
    return;
  }

  logger.info('Starting main app since should start app is true');
  const platformRecorder = new WebRecorder();
  const platformUploader = new WebUploader();
  const platformHelpers = new WebHelpers();

  Modal.setAppElement('#app');
  ReactDOM.render(
    <>
      <App platformRecorder={platformRecorder} platformUploader={platformUploader} platformHelpers={platformHelpers} />
    </>,
    document.getElementById('app')
  );
}

function secondTabApp() {
  ReactDOM.render(
    <div className="tab-in-use">
      <GlobalStyle />
      <h1>Welder is already open in another tab. Please close this tab and use the previous one.</h1>
    </div>,
    document.getElementById('app')
  );
}

function unavailableOnMobile() {
  ReactDOM.render(
    <div className="tab-in-use">
      <GlobalStyle />
      <h1>Welder is not accessible from mobile. Please open this link on your notebook or desktop.</h1>
    </div>,
    document.getElementById('app')
  );
}

function unavailableOnSafari() {
  ReactDOM.render(
    <div className="tab-in-use">
      <GlobalStyle />
      <h1>Welder is not accessible from Safari. Please open this link in Google Chrome.</h1>
    </div>,
    document.getElementById('app')
  );
}

function oldChromeVersion() {
  ReactDOM.render(
    <div className="tab-in-use">
      <GlobalStyle />
      <h1>Welder is not accessible from older version of Chrome. Please update your browser to latest version.</h1>
    </div>,
    document.getElementById('app')
  );
}

init()
