import React, { useEffect } from 'react';
import styled from 'styled-components';
import logger from '../../../util/logger';

function PermissionReject() {
  useEffect(() => {
    logger.info('Camera & mic permission denied. Currently on Rejected message page.');
  }, []);

  return (
    <Container>
      <Headline>Please enable camera & microphone to join the call.</Headline>
    </Container>
  );
}

export default PermissionReject;

const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Headline = styled.h1`
  color: #000000;
  font-size: 38px;
  font-weight: 400;
  margin: 0;
  margin-bottom: 100px;
`;
