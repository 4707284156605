import moment from "moment";
import React, { useEffect, useState }  from "react";


export default function useCountdown(isStarting: boolean, recordingStartTime: number) {
  const [countdown, setCountdown] = useState(0)

  useEffect(() => {
    if (!isStarting) {
      return;
    }

    function setTime() {
      const currentTimestamp = moment().valueOf()
      const seconds = Math.round((recordingStartTime - currentTimestamp) / 1000)
      setCountdown(seconds)
    }

    const interval = setInterval(() => {
      setTime()
    }, 1000)

    setTime()

    return () => {
      clearInterval(interval);
    }
  }, [recordingStartTime, isStarting])

  return countdown
}