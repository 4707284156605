import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  body {
   margin: 0;
   font-family: "jaf-bernina-sans";
   box-sizing: border-box;
   background: #f3f6f9;
   -webkit-font-smoothing: antialiased;
  }

  * {
    box-sizing: border-box;
    user-select: none;
  }

  .Toastify__toast {
    border-radius: 14px;
    font-weight: 600;
    font-family: "jaf-bernina-sans";
  }

  .Toastify__toast--info {
    background: black;
    color: white;
  }

  input, select, textarea {
    user-select: all;
  }

  .tippy-popper {
    top: 7px!important;
  }

  .Modal {
    position: absolute;
    outline: none;
    padding: 30px 40px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.45);
    border-radius: 33px;
    background-color: #ffffff;
  }

  .Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    z-index: 2;
  }

  .tab-in-use {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

    h1 {
      max-width: 600px;
      text-align: center;
      margin-bottom: 100px;
    }
  }
`;
