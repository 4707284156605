import React from 'react';
import styled from 'styled-components';
import Modal from 'react-modal';
import { useRecorder } from '../../hooks/recording/useRecorder';
import FeatureMenu from '../../components/FeatureMenu';
import { useSession } from '../../hooks/useSession';
import ModalClose from '../../components/ui/ModalClose';
import PreviewRecordingModal from '../../components/PreviewRecordingModal';
import RecordingBlock from '../../components/RecordingBlock';
import useVideoContext from '../../hooks/video/useVideoContext';
import { LocalVideoTrack } from 'twilio-video';
import VideoTrack from '../../components/VideoTrack';
import useCountdown from '../../hooks/recording/useCountdown';
import ScreenShareBlock from '../../components/ScreenShareBlock';


function SinglePlayerRoom() {
  const { localTracks } = useVideoContext();
  const { isRecording, scheduleRecording, isStarting, recordingStartTime, isTesting, scheduleTestRecording, toggleTestingModal, testingModalOpen } = useRecorder();
  const session = useSession();
  const countdown = useCountdown(isStarting, recordingStartTime)

  const videoTrack = localTracks.find((track) => track.name.includes('camera')) as LocalVideoTrack;

  return (
    <Container>
      {isStarting && countdown > 0 && (
        <CountdownContainer>
          <p>{countdown}</p>
        </CountdownContainer>
      )}

      {videoTrack ? <VideoTrack isLocal track={videoTrack} /> : null}

      <div>
        <RecordContainer>
          <RecordingBlock isHost={session.isHost} isRecording={isRecording} isTesting={isTesting} isStarting={isStarting} scheduleRecording={scheduleRecording} scheduleTestRecording={scheduleTestRecording} />
        </RecordContainer>
      </div>

      <ScreenContainer>
        <ScreenShareBlock />
      </ScreenContainer>

      <Modal overlayClassName="Overlay" className="Modal" isOpen={testingModalOpen} onRequestClose={() => toggleTestingModal(false)}>
        <ModalClose onClick={() => toggleTestingModal(false)} />
        <PreviewRecordingModal toggleModal={toggleTestingModal} previewUrl={session.sessionInfo.dashboardUrl} />
      </Modal>
    </Container>
  );
}

export default SinglePlayerRoom;

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const RecordContainer = styled.div`
  position: absolute;
  width: fit-content;
  bottom: 35px;
  left: 50%;
  transform: translateX(-50%);
`;

const CountdownContainer = styled.div`
  width: 100vw;
  height: 100vh;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;

  p {
    color: white;
    margin: 0;
    font-size: 362px;
  }
`;

const ScreenContainer = styled.div`
  position: absolute;
  bottom: 30px;
  left: 30px;
`

