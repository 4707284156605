import React from 'react';
import styled from 'styled-components';
import Headline from './ui/Headline';
import ActionButton from './ui/ActionButton';
import { useSession } from '../hooks/useSession';

interface ModalProps {
  leaveCall: () => void;
  toggleModal: (toggle: boolean) => void;
}

function LeaveCallModal(props: ModalProps) {
  const { isHost } = useSession();

  return (
    <Container>
      <Headline text="You are about to leave this call" />
      <Buttons>
        <ActionButton text="Stay on the call" onClick={() => props.toggleModal(false)} type="secondary" />
        <ActionButton
          id='leave-call'
          text="Leave call"
          icon="sign-out"
          onClick={() => {
            props.toggleModal(false);
            props.leaveCall();
          }}
        />
      </Buttons>
      <InfoText>
        {isHost
          ? 'The uploading of recorded files by each participant will start as soon as you leave the call.'
          : 'The uploading of recorded file will start as soon as you leave the call.'}
      </InfoText>
    </Container>
  );
}

export default LeaveCallModal;

const Container = styled.div`
  max-width: 582px;
`;

const Buttons = styled.div`
  display: flex;

  > *:first-child {
    margin-right: 22px;
  }
`;

const InfoText = styled.p`
  margin: 0;
  color: #c5ccd3;
  font-size: 14px;
  font-weight: 600;
  line-height: 22.97px;
  margin-top: 20px;
`;
