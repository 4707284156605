import { library, config } from '@fortawesome/fontawesome-svg-core';

// dont autoadd font awesome css
config.autoAddCss = false;

import {
  faPhone,
  faMicrophone,
  faMicrophoneSlash,
  faUserPlus,
  faVideo,
  faVideoSlash,
  faCog,
  faSignOut,
  faTimes,
  faLink,
  faChevronDown,
  faArrowRight,
  faCircle,
  faPlay,
  faStop,
  faDoorOpen,
  faExclamationCircle,
  faPause,
  faExpandWide,
  faCompressWide,
  faBrowser,
  faRepeatAlt,
  faThLarge,
  faMapPin,
  faMapMarker,
  faMapMarkerSlash,
  faDesktop, faEye, faCheckCircle, faSignInAlt, faCloudUploadAlt, faTag
} from "@fortawesome/pro-solid-svg-icons";

library.add(
  faPhone,
  faMicrophone,
  faMicrophoneSlash,
  faUserPlus,
  faVideo,
  faVideoSlash,
  faCog,
  faSignOut,
  faTimes,
  faLink,
  faChevronDown,
  faArrowRight,
  faCircle,
  faPlay,
  faStop,
  faExclamationCircle,
  faDoorOpen,
  faPause,
  faExpandWide,
  faCompressWide,
  faBrowser,
  faRepeatAlt,
  faThLarge,
  faMapPin,
  faMapMarker,
  faMapMarkerSlash,
  faDesktop,
  faEye,
  faSignInAlt,
  faCheckCircle,
  faTag,
  faCloudUploadAlt,
);
