import React, { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { LocalDataTrack, RemoteTrack } from 'twilio-video';
import logger from '../../util/logger';
import { useSession } from './useSession';
import useVideoContext from './video/useVideoContext';

type GenericEvents = 'start-recording' | 'pause-recording' | 'stop-recording' | 'stop-test-recording' | 'host-leave-call';
type Events = GenericEvents | 'recording-error';

export interface MessageMetadata {
  metadata: {
    participantId: string;
  };
}

interface ErrorMessage {
  type: 'recording-error';
  value: 'blob-save-error';
}

interface GenericMessage {
  type: Events;
  value?: string | number;
}

type Message = GenericMessage | ErrorMessage;
export type ReceivedMessage = Message & MessageMetadata

interface DataChannelType {
  broadcastMessage: (msg: Message) => void;
  on: (type: Events, cb: Function) => void;
  off: (type: Events, cb: Function) => void;
}

export const DataChannelContext = createContext<DataChannelType>(null!);

export default function useDataChannel() {
  const context = useContext(DataChannelContext);
  if (!context) {
    throw new Error('useDataChannel must be used within the DataChannelProvider');
  }
  return context;
}

export function DataChannelProvider(props: React.PropsWithChildren<{}>) {
  const { localTracks } = useVideoContext();
  const { room } = useVideoContext();
  const { participantInfo } = useSession();
  const [callbacks, setEvent] = useState<{ [key in Events]: Function[] }>({
    'start-recording': [],
    'pause-recording': [],
    'host-leave-call': [],
    'stop-recording': [],
    'recording-error': [],
    'stop-test-recording': []
  });

  const callbacksRef = useRef(callbacks);

  useEffect(() => {
    callbacksRef.current = callbacks;
  }, [callbacks]);

  const localDataTrack = (useMemo(() => localTracks.find((track) => track.kind === 'data'), [localTracks]) as unknown) as LocalDataTrack;

  const trackAdded = (track: RemoteTrack) => {
    if (track.kind === 'data') track.on('message', handleMessage);
  };

  const trackRemoved = (track: RemoteTrack) => {
    if (track.kind === 'data') track.off('message', handleMessage);
  };

  useEffect(() => {
    room.on('trackSubscribed', trackAdded);
    room.on('trackUnsubscribed', trackRemoved);

    return () => {
      room.off('trackSubscribed', trackAdded);
      room.off('trackUnsubscribed', trackRemoved);
    };
  }, [room]);

  const broadcastMessage = useCallback(
    (msg: Message) => {
      logger.info('Broadcasting message on channel', msg);

      const metadata: MessageMetadata = {
        metadata: {
          participantId: participantInfo.id,
        },
      };

      const packedMsg = Object.assign(msg, metadata);
      localDataTrack?.send(JSON.stringify(packedMsg));
    },
    [localDataTrack, room.localParticipant, participantInfo]
  );

  const handleMessage = useCallback(
    (data: string) => {
      const msg: Message & MessageMetadata = JSON.parse(data);

      logger.info('Received message from channel', msg);
      callbacksRef.current[msg.type].forEach((cb) => {
        cb.call(undefined, msg.value, msg.metadata);
      });
    },
    [callbacks]
  );

  function on(type: Events, cb: Function) {
    setEvent((prevState) => {
      return { ...prevState, [type]: prevState[type].concat(cb) };
    });
  }

  function off(type: Events, cb: Function) {
    setEvent((prevState) => {
      return {
        ...prevState,
        [type]: prevState[type].filter((item) => item !== cb),
      };
    });
  }

  return (
    <DataChannelContext.Provider
      value={{
        on,
        off,
        broadcastMessage,
      }}
    >
      {props.children}
    </DataChannelContext.Provider>
  );
}
