import React from 'react';
import Headline from './ui/Headline';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { lighten } from 'polished';
import { toast } from 'react-toastify';

interface AddGuestModalProps {
  copyLinkText: string;
}

function AddGuestModal(props: AddGuestModalProps) {
  function copyText() {
    navigator.clipboard
      .writeText(props.copyLinkText)
      .then(() => {
        toast('Copied to clipboard');
      })
      .catch((err) => {
        toast.error('Could not copy link');
        console.error('Something went wrong', err);
      });
  }

  return (
    <Container>
      <Headline text="Invite others" />
      <Button>
        <CopyText>
          <p>{props.copyLinkText}</p>
        </CopyText>
        <Action onClick={() => copyText()}>
          <FontAwesomeIcon icon="link" />
          <p>Copy link</p>
        </Action>
      </Button>
      <InfoText>
        Send this link to your guests. They will be able to join from Chrome, Edge. In Safari & Firefox they will get an 'open in chrome' page.
      </InfoText>
    </Container>
  );
}

const Container = styled.div`
  max-width: 652px;
`;

const Button = styled.div`
  display: flex;
  width: 100%;
`;

const CopyText = styled.div`
  border: 2px solid #d7dfe8;
  padding: 20px;
  border-radius: 14px 0 0 14px;
  flex: 1;
  border-right: 0;
  user-select: text;

  p {
    color: #000000;
    font-size: 18px;
    font-weight: 600;
    margin: 0;
  }
`;

const Action = styled.div`
  border-radius: 0 14px 14px 0;
  border: 2px solid #f4c344;
  background-color: #feca45;
  padding: 17px 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;

  p,
  svg {
    color: #000000;
    font-size: 18px;
    margin: 0;
  }

  svg {
    font-weight: 400;
    margin-right: 10px;
  }

  p {
    font-weight: 700;
  }

  &:hover {
    background-color: ${() => lighten(0.05, '#feca45')};
  }
`;

const InfoText = styled.p`
  margin: 0;
  color: #c5ccd3;
  font-size: 14px;
  font-weight: 600;
  line-height: 22.97px;
  margin-top: 20px;
`;

export default AddGuestModal;
