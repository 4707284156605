import React from 'react';
import ActionInput from '../components/ui/ActionInput';
import styled from 'styled-components';
import { useAppState } from '../hooks/useAppState';
import UploadNotification from '../components/UploadNotification';
import useUploader from '../hooks/useUploader';
import RejoinSession from '../components/RejoinSession';
import parseJoinUrl from '../../util/app/parseJoinUrl';

function Home() {
  const state = useAppState();
  const uploader = useUploader();

  async function enterClick(val: string) {
    const { slug, hostToken } = parseJoinUrl(val);
    await state.joinSession(slug, hostToken);
  }

  const lastCall = state.lastCall;
  return (
    <Container>
      {uploader.hasActiveUploads && <UploadNotification />}
      <Content>
        <Headline>Welcome to Welder</Headline>
        <LinkInput>
          <Text>Paste your link</Text>
          <ActionInput
            onSubmit={enterClick}
            placeholder="https://join.staging.getwelder.com/#4aeo6h"
            icon="arrow-right"
            buttonText={state.isEnteringRoom ? 'Joining...' : 'Join'}
            disabled={state.isEnteringRoom}
          />
        </LinkInput>
        {lastCall && <RejoinSession />}
      </Content>
    </Container>
  );
}

export default Home;

const Content = styled.div`
  max-width: 670px;
  width: 100%;
`;

const Headline = styled.h1`
  color: #000000;
  font-size: 38px;
  font-weight: 400;
  margin: 0;
  margin-bottom: 33px;
`;

const Text = styled.p`
  margin: 0;
  margin-bottom: 7px;
  color: #949ba3;
  font-size: 18px;
  font-weight: 600;
  line-height: 29.63px;
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const LinkInput = styled.div`
  box-shadow: 0 6px 20px rgba(148, 155, 163, 0.06);
  border-radius: 33px;
  background-color: #ffffff;
  padding: 45px 55px 55px 55px;
  margin-bottom: 30px;
`;
