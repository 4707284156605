import React from 'react';
import Headline from './ui/Headline';
import Dropdown from './ui/Dropdown';
import styled from 'styled-components';
import { useAudioInputDevices, useAudioOutputDevices, useVideoInputDevices } from '../hooks/useVideoControls';
import { LocalVideoTrack } from 'twilio-video';
import useVideoContext from '../hooks/video/useVideoContext';

function DeviceSelectModal() {
  const audioInputDevices = useAudioInputDevices();
  const videoInputDevices = useVideoInputDevices();
  const audioOutputDevices = useAudioOutputDevices()

  const {
    room: { localParticipant },
    localTracks,
    getLocalAudioTrack,
    getLocalVideoTrack,
    audioOutput
  } = useVideoContext();

  const localAudioTrack = localTracks.find((track: { kind: string }) => track.kind === 'audio');
  // @ts-ignore
  const localAudioInputDeviceId = localAudioTrack?.mediaStreamTrack.getSettings().deviceId;

  const localVideoTrack = localTracks.find((track) => track.kind === 'video' && track.name.includes('camera')) as LocalVideoTrack | undefined;

  const localVideoInputDeviceId = localVideoTrack?.mediaStreamTrack.getSettings().deviceId;

  function replaceTrack(newDeviceId: string, kind: 'audio' | 'video') {
    const track = kind === 'audio' ? localAudioTrack : localVideoTrack;
    // @ts-ignore
    track?.stop();

    const newTrack = kind === 'audio' ? getLocalAudioTrack(newDeviceId) : getLocalVideoTrack({ deviceId: { exact: newDeviceId } });

    // @ts-ignore
    newTrack.then((newTrack: any) => {
      if (track) {
        const localTrackPublication = localParticipant?.unpublishTrack(track);
        // TODO: remove when SDK implements this event. See: https://issues.corp.twilio.com/browse/JSDK-2592
        localParticipant?.emit('trackUnpublished', localTrackPublication);
      }

      localParticipant?.publishTrack(newTrack);
    });
  }

  return (
    <Container>
      <Headline text="Settings" />
      <DropdownContainer>
        <Dropdown
          options={audioInputDevices.map((item) => {
            return { text: item.label, value: item.deviceId };
          })}
          value={localAudioInputDeviceId}
          label={'Microphone'}
          onSelect={(val) => replaceTrack(val, 'audio')}
        />

        <Dropdown
          options={videoInputDevices.map((item) => {
            return { text: item.label, value: item.deviceId };
          })}
          value={localVideoInputDeviceId}
          label={'Camera'}
          onSelect={(val) => replaceTrack(val, 'video')}
        />

        <Dropdown
          options={audioOutputDevices.map((item) => {
            return { text: item.label, value: item.deviceId };
          })}
          value={audioOutput.deviceId}
          label={'Audio Output'}
          onSelect={(val) => audioOutput.setDevice(val)}
        />
      </DropdownContainer>
    </Container>
  );
}

export default DeviceSelectModal;

const Container = styled.div`
  min-width: 582px;
`;

const DropdownContainer = styled.div`
  > * {
    margin-bottom: 25px;
  }
`;
