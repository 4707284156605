import React from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import { lighten } from 'polished';

interface ActionInputProps {
  onSubmit: (val: string) => void;
  placeholder: string;
  icon: IconProp;
  buttonText: string;
  disabled?: boolean;
}

function ActionInput(props: ActionInputProps) {
  const [val, changeVal] = React.useState('');

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        props.onSubmit(val);
      }}
    >
      <Input
        name="link"
        type="url"
        value={val}
        required
        onChange={(e) => changeVal(e.target.value)}
        placeholder={props.placeholder}
      />
      <Button disabled={props.disabled}>
        {props.buttonText}
        <FontAwesomeIcon icon={props.icon} />
      </Button>
    </Form>
  );
}

export default ActionInput;

const Form = styled.form`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Input = styled.input`
  border: 2px solid #d7dfe8;
  background-color: #ffffff;
  outline: none;
  appearance: none;
  padding: 18px 22px;
  flex: 1;
  border-radius: 14px 0px 0px 14px;
  color: #000000;
  font-size: 18px;
  font-weight: 600;

  ::placeholder,
  ::-webkit-input-placeholder {
    color: #d7dfe8;
  }
`;

const Button = styled.button`
  outline: none;
  appearance: none;
  padding: 18px 22px;
  border: 2px solid #feca45;
  background-image: linear-gradient(90deg, #ffd260 0%, #feca45 100%);
  color: #000000;
  font-size: 18px;
  font-weight: 700;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0 14px 14px 0;
  margin-left: -2px;
  user-select: none;

  svg {
    font-size: 15px;
    font-weight: 400;
    margin-left: 27px;
  }

  &:hover {
    background-image: linear-gradient(
      90deg,
      ${() => lighten(0.05, '#ffd260')} 0%,
      ${() => lighten(0.05, '#feca45')} 100%
    );
  }
`;
