import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled, { css } from 'styled-components';
import { Tooltip } from 'react-tippy';
import useCallControls from '../hooks/controls/useCallControls';
// @ts-ignore
import DominantIcon from '../assets/dominant-icon.svg';
// @ts-ignore
import StopScreen from '../assets/stop-screen.svg';

function FeatureMenu() {
  const { isHiddenUI, toggleUIHide, callLayout, switchCallLayout, isScreenSharing, toggleScreenShare } = useCallControls();

  return (
    <Container hidden={isHiddenUI}>
      {!isHiddenUI ? (
        <>
          <Tooltip distance={15} position="top" title="Hide UI" theme="light">
            <Item onClick={() => toggleUIHide(true)}>
              <FontAwesomeIcon icon="expand-wide" />
            </Item>
          </Tooltip>
          <Tooltip distance={15} position="top" title={callLayout === 'grid' ? 'Switch to dominant layout' : 'Switch to grid layout'} theme="light">
            <Item
              style={{
                padding: callLayout === 'grid' ? '17px' : '16px',
                paddingLeft: callLayout === 'grid' ? '13.5px' : '16px',
                paddingRight: callLayout === 'grid' ? '13.5px' : '16px',
              }}
              onClick={() => switchCallLayout(callLayout === 'grid' ? 'dominant' : 'grid')}
            >
              {callLayout === 'grid' ? <img alt="dominant layout" src={DominantIcon} /> : <FontAwesomeIcon icon="th-large" />}
            </Item>
          </Tooltip>
          <Tooltip position="top" distance={15} title={isScreenSharing ? 'Stop Screen Share' : 'Share Screen'} theme="light">
            <Item id="screen-share-toggle" style={{ width: '47px', height: '47px', padding: '13px' }} onClick={() => toggleScreenShare()}>
              {isScreenSharing ? <img src={StopScreen} alt="Stop screen sharing" /> : <FontAwesomeIcon icon="desktop" />}
            </Item>
          </Tooltip>
        </>
      ) : (
        <Tooltip distance={15} title="Show UI" theme="light">
          <Item hidden onClick={() => toggleUIHide(false)}>
            <FontAwesomeIcon icon="compress-wide" />
          </Item>
        </Tooltip>
      )}
    </Container>
  );
}

export default FeatureMenu;

interface ItemsProps {
  disableHover?: boolean;
  bold?: boolean;
  hidden?: boolean;
}

const Item = styled.div<ItemsProps>`
  padding: 16px;
  border-radius: 16px;
  background-color: rgba(255, 255, 255, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  color: #000000;
  font-size: 15px;
  font-weight: 400;
  line-height: 15px;

  &:hover {
    background-color: rgba(255, 255, 255, 0.9);
  }

  ${(props) =>
    props.bold &&
    `
    font-weight: 800;
  `}

  ${(props) =>
    props.disableHover &&
    css`
      cursor: initial;
      &:hover {
        background-color: rgba(255, 255, 255, 0.7);
      }
    `}

  ${(props) =>
    props.hidden &&
    css`
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
      padding-bottom: 6px;
      padding-top: 14px;
      padding-right: 13px;
    `}
`;

interface Props {
  hidden: boolean;
}

const Container = styled.div<Props>`
  display: flex;
  position: absolute;
  left: 30px;
  bottom: 40px;

  > * {
    margin-left: 10px;
  }

  ${(props) =>
    props.hidden &&
    css`
      left: -8px;
      bottom: 0px;

      > * {
        margin-left: 0px;
      }
    `}
`;
