import React from 'react';
import styled from 'styled-components';

interface GridItemProps {
  width: number;
  height: number;
  children: React.ReactNode;
}

export default function GridItem(props: GridItemProps) {
  return <Container style={{ width: props.width, height: props.height }}>{props.children}</Container>;
}

const Container = styled.div`
  margin: 5px;
  width: 100%;
  position: relative;
  border-radius: 6px;
  border: 1px solid #2a2b2c;
  overflow: hidden;
`;
