import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import logger from '../../util/logger';
import { usePlatform } from './platform/usePlatform';
import {IUploadStats} from "../platform/PlatformUploader";

interface UploaderType {
  hasActiveUploads: boolean;
  checkingUploads: boolean;
  checkNewUploads: () => Promise<void>;
  pauseUploads: () => void;
  resumeUploads: () => void;
}

export const UploaderContext = createContext<UploaderType>(null!);

export default function useUploader() {
  const context = useContext(UploaderContext);
  if (!context) {
    throw new Error('useUploader must be used within the UploaderProvider');
  }
  return context;
}

export function UploaderProvider(props: React.PropsWithChildren<{}>) {
  const { platformUploader, platformHelpers } = usePlatform();
  const [checkingUploads, setCheckingUploads] = useState(false);
  const [hasActiveUploads, setActiveUploads] = useState(false);

  useEffect(() => {
    function setActive(status: IUploadStats[]) {
      setActiveUploads(!!status.length)
    }

    setActive(platformUploader.uploads)
    platformUploader.on('upload-status-update', setActive);
    return () => {
      platformUploader.off('upload-status-update', setActive);
    };
  }, [platformUploader, hasActiveUploads]);

  const checkNewUploads = useCallback(async () => {
    setCheckingUploads(true);
    logger.info('Checking for new uploads from renderer...');
    await platformHelpers.leftCall();
    await platformUploader.checkForNewUploads();
    setCheckingUploads(false);
  }, []);

  function pauseUploads() {
    platformUploader.pauseUploads()
    logger.info('Uploads paused')
  }

  function resumeUploads() {
    platformUploader.resumeUploads()
    logger.info('Uploads resumed')
  }

  return (
    <UploaderContext.Provider
      value={{
        hasActiveUploads,
        checkingUploads,
        checkNewUploads,
        pauseUploads,
        resumeUploads
      }}
    >
      {props.children}
    </UploaderContext.Provider>
  );
}

export function useUploadStats() {
  const { platformUploader } = usePlatform();
  const [stats, setStatus] = useState<IUploadStats[]>([]);

  function updateStatus(status: IUploadStats[]) {
    setStatus(status);
  }

  useEffect(() => {
    updateStatus(platformUploader.uploads)
    platformUploader.on('upload-status-update', updateStatus);

    return () => {
      platformUploader.off('upload-status-update', updateStatus);
    };
  }, [platformUploader]);

  return stats
}
