import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import { darken } from 'polished';

interface ModalCloseProps {
  onClick: () => void;
}

function ModalClose(props: ModalCloseProps) {
  return (
    <Container onClick={props.onClick}>
      <FontAwesomeIcon icon="times" />
    </Container>
  );
}

const Container = styled.div`
  width: 28px;
  height: 28px;
  background-color: #d7dfe8;
  position: absolute;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  right: 0;
  cursor: pointer;

  > svg {
    color: #6d7783;
    font-size: 14px;
    font-weight: 400;
  }

  &:hover {
    background-color: ${() => darken(0.1, '#d7dfe8')};
  }
`;

export default ModalClose;
