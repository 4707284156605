import React from 'react';
import styled from 'styled-components';

function Waiting() {
  return (
    <Container>
      <p>Waiting for others to join...</p>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    color: #feca45;
    font-size: 18px;
    font-weight: 700;
  }
`;

export default Waiting;
