import React from 'react';
import styled from 'styled-components';

interface SidebarContainerProps {
  children: React.ReactNode[];
}

export default function SidebarContainer(props: SidebarContainerProps) {
  return (
    <GradientWrapper>
      <Container>{props.children}</Container>
      <ScrollGradient />
    </GradientWrapper>
  );
}

const GradientWrapper = styled.div`
  position: relative;
`;

const Container = styled.div`
  height: 100%;
  flex: 1;
  width: 320px;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  border-radius: 6px;

  > * {
    margin-bottom: 10px;
  }

  > *:last-child {
    margin-bottom: 0px;
  }

  padding-bottom: 70px;

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
`;

const ScrollGradient = styled.div`
  width: 332px;
  height: 211px;
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
  position: absolute;
  bottom: 0px;
  pointer-events: none;
`;
