import React, { useState } from 'react';
import styled from 'styled-components';
import { LocalParticipant, RemoteParticipant, RemoteVideoTrack } from 'twilio-video';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface SidebarItemProps {
  children: React.ReactNode;
  participant: LocalParticipant | RemoteParticipant;
  selectedTrack?: RemoteVideoTrack | null;
  setSelectedTrack?: (track: RemoteVideoTrack | null) => void;
  track: RemoteVideoTrack;
}

export default function SidebarItem(props: SidebarItemProps) {
  const [hover, setHover] = useState(false);
  const canSelect = !!props.setSelectedTrack;

  return (
    <Container onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
      <PinContainer visible={canSelect && hover} onClick={() => props.setSelectedTrack!(props.track)}>
        <div>
          <FontAwesomeIcon icon="map-marker" />
          <p>Pin View</p>
        </div>
      </PinContainer>
      {props.children}
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  min-height: 200px;
  border-radius: 6px;
  overflow: hidden;
  position: relative;
`;

const PinContainer = styled.div<{ visible: boolean }>`
  width: 100%;
  height: 100%;
  border-radius: 6px;
  background-color: rgba(0, 0, 0, 0.6);
  position: absolute;
  top: 0;
  opacity: ${(props) => (props.visible ? 1 : 0)};
  transition: 0.1s opacity;

  pointer-events: ${(props) => (props.visible ? 'auto' : 'none')};;
  cursor: ${(props) => (props.visible ? 'pointer' : 'initial')};

  display: flex;
  justify-content: center;
  align-items: center;

  z-index: 999;

  div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  svg,
  p {
    color: #ffffff;
    font-size: 12px;
    font-weight: 700;
    font-style: normal;
    letter-spacing: normal;
    line-height: normal;
    text-align: center;
    margin: 0;
  }

  p {
    margin-top: 8px;
  }

  svg {
    font-size: 18px;
    font-weight: 400;
  }
`;
