import { EventEmitter2 as EventEmitter } from 'eventemitter2';
import {
  addRecordingBlob,
  createRecording,
  resetAllRecordingsStatuses,
  IRecording,
  IRecordingBlob,
  markRecordingAsDone,
  getRecording
} from "../util/app/db";
import logger from '../util/logger';
import { IPlatformRecorder, RecordingErrorTypes, RecordingType } from '../core/platform/PlatformRecorder';
import Sentry from '../util/sentry';

export default class WebRecorder extends EventEmitter implements IPlatformRecorder {

  hasRecordingDevice = false;
  showSize = false;
  isRecording = false;

  recorders: StreamRecorder[] = []

  constructor() {
    super();
    resetAllRecordingsStatuses();
  }

  async addRecordingStream(stream: MediaStream, participantId: string, type: RecordingType) {
    logger.info('Trying to add new recording stream with type:', type)

    const recorder = new StreamRecorder(type)
    const self = this;

    // reemit all recorder events and push them upwards. We can then catch them and error out entire recording session
    recorder.on('*', function (...values: any[]) {
      self.emit(this.event, ...values)
    })

    await recorder.addRecordingStream(stream, participantId)
    this.recorders.push(recorder)
  }

  async startRecording() {
    console.log(this.recorders.length, this.recorders)
    await Promise.all(this.recorders.map(recorder => recorder.startRecording()))
  }

  async stopRecording() {
    await Promise.all(this.recorders.map(recorder => recorder.stopRecording()))
    this.recorders.forEach(recorder => {
      recorder.removeAllListeners()
    })
    this.recorders = []
  }

  async getRecordingsInfo() {
    return await Promise.all(this.recorders.map(recorder => recorder.getRecordingInfo()))
  }
}

class StreamRecorder extends EventEmitter {
  recordingStream: MediaStream | null = null;
  private recorder: MediaRecorder | undefined;
  private activeRecordingId: number | undefined;
  private participantId: string | null = null;
  private totalRecordingSize = 0;
  private blobOrderingNumber = 0;

  recordingType: RecordingType;

  // how many 0 size blobs in a row to throw an error stop recording?
  private maxEmptyBlobs = 3;
  private currentEmptyBlobs = 0;


  constructor(type: RecordingType) {
    super({wildcard: true})
    this.recordingType = type;
  }

  async addRecordingStream(stream: MediaStream, participantId: string) {
    logger.info('Creating recorder stream: ', stream.id)
    this.recordingStream = stream

    try {
      this.createRecorder()
    } catch (e) {
     this.onError(e, 'recording-start-generic-error')
    }

    this.participantId = participantId;
    logger.info('Recorder was creating is waiting for activation')
  }

  createRecorder() {
    const options = this.getRecordingOptions();
    this.recorder = new MediaRecorder(this.recordingStream!, options);
    this.recorder.ondataavailable = this.onBlobData.bind(this);
    this.recorder.onerror = (e: any) => this.onError(e, 'recording-start-generic-error')
  }

  async onBlobData(blobEvent: BlobEvent) {
    this.blobOrderingNumber += 1;
    const orderingNumber = this.blobOrderingNumber;
    const blob = blobEvent.data;
    logger.info(`received recording blob with size: ${blob.size} (${Math.floor(blob.size / 1000 / 1000)} MB) and type: ${blob.type}. Ordering number is: ${this.blobOrderingNumber}`)

    if (blob.size === 0) {
      logger.warn(`Blob ${this.blobOrderingNumber} has 0 bytes`)
      this.currentEmptyBlobs++;
    }

    if (this.currentEmptyBlobs >= this.maxEmptyBlobs) {
      logger.warn(`blob size is 0 and exceeded maxEmptyBlobs (${this.maxEmptyBlobs}). Stop recording for everyone.`, this.blobOrderingNumber);
      this.onError(new Error(`Blob ${this.blobOrderingNumber} has no bytes`), 'recording-start-generic-error')
      return;
    }

    // we need to convert blob to ArrayBuffer in order to save to DB. There should be no problem in saving
    // Blobs to indexdb but it seems that garbage collection is somehow slower on chrome for blobs. Which means
    // a lot of them will stay in memory and after some 'count' chrome will start actively purging contents of the
    // LIVE blobs (not yet uploaded ones). ArrayBuffer does not have this problem. We should revisit this later since
    // conversion to buffer takes time & saving a blob to DB is faster then saving arrayBuffer
    const buffer = await blob.arrayBuffer();

    this.totalRecordingSize += blob.size;

    const recordingBlob: IRecordingBlob = {
      orderingNumber: orderingNumber,
      buffer: buffer,
      blobSizeBytes: buffer.byteLength,
      recordingId: this.activeRecordingId!,
      uploading: 0,
    };

    try {
      const primaryKey = await addRecordingBlob(this.activeRecordingId!, recordingBlob, this.totalRecordingSize);
      logger.info(`Recording blob ${primaryKey} saved for recording ${this.activeRecordingId}`);
    } catch (e) {
      this.onError(e, 'blob-save-error');
    }
  }

  getRecordingOptions() {
    const videoBitrate = this.getVideoBitRate()

    logger.info('Using video birate of: ', videoBitrate)
    return {
      type: 'video',
      checkForInactiveTracks: false,
      // only for audio track
      audioBitsPerSecond: 128000,
      // only for video track
      videoBitsPerSecond: videoBitrate,
    };
  }

  onError(e: Error, type: RecordingErrorTypes) {
    logger.error(`recording error ${type}: `, e);
    Sentry.captureException(e);
    this.emit('error', type);
  }

  async startRecording() {
    logger.info('Starting recording for participant: ', this.participantId!);
    this.totalRecordingSize = 0;
    this.blobOrderingNumber = 0;
    this.currentEmptyBlobs = 0;

    if (!this.participantId) {
      this.onError(new Error('Recording not initialized'), 'recording-start-no-device-error');
      return;
    }

    const recording: IRecording = {
      participantId: this.participantId!,
      type: this.recordingType,
      recordingInProgress: true,
      totalBytes: 0,
      uploadedBytes: 0,
      createdTimestamp: Date.now(),
    };

    try {
      this.activeRecordingId = await createRecording(recording);
      this.recorder!.start(15000);
      logger.info('Recording successfully started')
    } catch (e) {
      this.onError(e, 'recording-start-generic-error');
    }
  }

  async stopRecording() {
    if (!this.recorder) {
      this.onError(new Error('Cannot stop recording because recording is not created'), 'recording-start-generic-error')
      return;
    }

    if (this.recorder.state !== 'inactive') {
      logger.info('Stopping recording for participant: ', this.participantId!);
      this.recorder!.stop();
      logger.info('Recording Stopped')
    } else {
      logger.info('recording already inactive');
    }

    await markRecordingAsDone(this.activeRecordingId!);
    logger.info('Recording marked as done...', this.activeRecordingId!)
  }

  private getVideoBitRate() {
    if (!this.recordingStream) {
      throw new Error('Cannot get video bitrate without active recording stream');
    }

    const videoTrack = this.recordingStream.getVideoTracks()[0];

    if (!videoTrack) {
      return 2_097_152;
    }

    const bitrateScaling = {
      1280: 6,
      1920: 10,
      4096: 15,
    };

    const trackWidth = videoTrack.getSettings().width!;

    const closestWidth = (Object.keys(bitrateScaling).reduce((prev, curr) =>
      Math.abs(parseInt(curr) - trackWidth) < Math.abs(parseInt(prev) - trackWidth) ? curr : prev
    ) as unknown) as keyof typeof bitrateScaling;

    const selectedScaling = bitrateScaling[closestWidth];
    return (trackWidth / closestWidth) * selectedScaling * 1000 * 1000;
  }

  async getRecordingInfo() {
    if (this.activeRecordingId) {
      return getRecording(this.activeRecordingId)
    }

    return;
  }
}