import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useAppState } from '../hooks/useAppState';
import { useHistory } from 'react-router-dom';
import parseJoinUrl from '../../util/app/parseJoinUrl';

function CallJoiner() {
  const state = useAppState();
  const history = useHistory();

  useEffect(() => {
    async function joinCall() {
      const { slug, hostToken } = parseJoinUrl(window.location.href);
      try {
        await state.joinSession(slug, hostToken);
      } catch (e) {
        history.push('/');
        throw e;
      }
    }

    joinCall();
  }, [history, history.location.pathname]);

  return (
    <Container>
      <Headline>Loading...</Headline>
    </Container>
  );
}

export default CallJoiner;

const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Headline = styled.h1`
  color: #000000;
  font-size: 38px;
  font-weight: 400;
  margin: 0;
  margin-bottom: 100px;
`;
