import { useEffect, useRef } from 'react';
import useVideoContext from '../hooks/video/useVideoContext';

interface AudioTrackProps {
  track: any;
}

export function AudioTrack({ track }: AudioTrackProps) {
  const { audioOutput } = useVideoContext();

  const activeSinkId = audioOutput.deviceId;
  const audioEl = useRef<HTMLAudioElement>();

  useEffect(() => {
    audioEl.current = track.attach();
    audioEl.current!.setAttribute('data-cy-audio-track-name', track.name);
    document.body.appendChild(audioEl.current!);
    return () => {
      track.detach(audioEl.current).remove();
    };
  }, [track]);

  useEffect(() => {
    // @ts-ignore
    audioEl.current?.setSinkId?.(activeSinkId);
  }, [activeSinkId]);

  return null;
}

export default AudioTrack;
