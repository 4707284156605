import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface DropdownProps {
  options: { text: string; value: string }[];
  value?: string;
  label: string;
  onSelect: (val: string) => void;
}

function Dropdown(props: DropdownProps) {
  const options = props.options.map((item) => (
    <option key={item.value} value={item.value}>
      {item.text}
    </option>
  ));

  return (
    <Container>
      <Label htmlFor={props.label}>{props.label}</Label>
      <SelectContainer>
        <Select
          value={props.value}
          onChange={(e) => props.onSelect(e.target.value)}
          name={props.label}
        >
          {options}
        </Select>
        <FontAwesomeIcon icon="chevron-down" />
      </SelectContainer>
    </Container>
  );
}

export default Dropdown;

const Container = styled.div`
  width: 100%;
`;

const Label = styled.label`
  color: #949ba3;
  font-size: 16px;
  font-weight: 600;
  line-height: 26.34px;
  display: block;
  margin-bottom: 10px;
`;

const SelectContainer = styled.div`
  position: relative;
  width: 100%;

  > svg {
    position: absolute;
    right: 20px;
    top: 20px;
  }
`;

const Select = styled.select`
  appearance: none;
  padding: 18px 20px;
  border-radius: 14px;
  border: 2px solid #d7dfe8;
  width: 100%;
  outline: none;
  color: #000000;
  font-size: 18px;
  font-weight: 600;
`;
