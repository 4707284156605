import React from 'react';
import styled from 'styled-components';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface HeadlineProps {
  text: string;
  icon?: IconProp
}

function Headline(props: HeadlineProps) {
  return <HeadlineText>
    {props.icon && <FontAwesomeIcon icon={props.icon} />}
    {props.text}
  </HeadlineText>;
}

const HeadlineText = styled.div`
  color: #000000;
  font-size: 26px;
  font-weight: 700;
  line-height: 42.8px;
  margin: 0 0 20px;
  display: flex;
  align-items: center;

  svg {
    margin-right: 10px;
  }
`;

export default Headline;
