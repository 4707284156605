import React from 'react';
import styled from 'styled-components';
import MenuItem from './MenuItem';
import Modal from 'react-modal';
import AddGuestModal from './AddGuestModal';
import ModalClose from './ui/ModalClose';
import DeviceSelectModal from './DeviceSelectModal';
import LeaveCallModal from './LeaveCallModal';
import { useRecorder } from '../hooks/recording/useRecorder';
import useCallControls from '../hooks/controls/useCallControls';
import { useSession } from '../hooks/useSession';
import useRoomState from '../hooks/video/useRoomState';

function Menu() {
  const {
    toggleUserOpen,
    isAudioEnabled,
    toggleAudioEnabled,
    isVideoEnabled,
    toggleVideoEnabled,
    toggleDeviceModal,
    isClosing,
    toggleLeaveOpen,
    userOpen,
    deviceOpen,
    leaveOpen,
    leaveRoom,
  } = useCallControls();

  const { isRecording } = useRecorder();
  const roomState = useRoomState();
  const session = useSession();

  return (
    <Container>
      <MenuItem tooltip="Invite guest" icon="user-plus" onClick={() => toggleUserOpen(true)} />
      <MenuItem
        tooltip={isAudioEnabled ? 'Mute microphone' : 'Unmute microphone'}
        icon={isAudioEnabled ? 'microphone' : 'microphone-slash'}
        onClick={() => toggleAudioEnabled()}
        id='toggle-audio'
      />
      <MenuItem
        tooltip={isVideoEnabled ? 'Turn video off' : 'Turn video on'}
        icon={isVideoEnabled ? 'video' : 'video-slash'}
        onClick={() => toggleVideoEnabled()}
        id='toggle-video'
      />
      <MenuItem
        tooltip={isRecording ? 'Settings are disabled during recording - To change camera and microphone first stop the recording' : 'Settings'}
        icon="cog"
        disabled={isRecording}
        onClick={() => toggleDeviceModal(true)}
      />
      <MenuItem
        disabled={isClosing}
        loading={isClosing}
        tooltip={session.isHost ? 'End the call for all participants. It will take them to a screen to finish the uploading.' : 'Leave call'}
        icon="sign-out"
        id='leave-call-modal-open'
        onClick={() => (roomState === 'connected' ? toggleLeaveOpen(true) : leaveRoom())}
      />
      <Modal overlayClassName="Overlay" className="Modal" isOpen={userOpen} onRequestClose={() => toggleUserOpen(false)}>
        <ModalClose onClick={() => toggleUserOpen(false)} />
        <AddGuestModal copyLinkText={`https://web.getwelder.com/j/#${session.sessionSlug}`} />
      </Modal>
      <Modal overlayClassName="Overlay" className="Modal" isOpen={deviceOpen} onRequestClose={() => toggleDeviceModal(false)}>
        <ModalClose onClick={() => toggleDeviceModal(false)} />
        <DeviceSelectModal />
      </Modal>
      <Modal overlayClassName="Overlay" className="Modal" isOpen={leaveOpen} onRequestClose={() => toggleLeaveOpen(false)}>
        <ModalClose onClick={() => toggleLeaveOpen(false)} />
        <LeaveCallModal leaveCall={leaveRoom} toggleModal={toggleLeaveOpen} />
      </Modal>
    </Container>
  );
}

export default Menu;

const Container = styled.div`
  position: absolute;
  right: 30px;
  bottom: 40px;
  display: flex;

  > * {
    margin-right: 10px;
  }
`;
