import React, { useState } from 'react';
import { LocalVideoTrack, Participant as IParticipant, RemoteVideoTrack } from 'twilio-video';
import { useDominantSpeaker } from '../hooks/participants/participant';
import VideoTrack from './VideoTrack';
import styled from 'styled-components';
import useVideoContext from '../hooks/video/useVideoContext';
import ParticipantUploadStatus from './ParticipantUploadStatus';

interface ParticipantTrackProps {
  participant: IParticipant;
  videoStyle?: React.CSSProperties;
  track: LocalVideoTrack | RemoteVideoTrack;
}

function ParticipantTrack(props: ParticipantTrackProps) {
  const [showContent, toggleShowContent] = useState(false);

  const { room } = useVideoContext();
  const isLocal = props.participant === room.localParticipant;
  const dominantSpeaker = useDominantSpeaker();
  const isDominantSpeaker = dominantSpeaker === props.participant;
  const priority = (isDominantSpeaker ? 'high' : 'low') as any;

  return (
    <Container onMouseEnter={() => toggleShowContent(true)} onMouseLeave={() => toggleShowContent(false)}>
      <VideoTrack videoStyle={props.videoStyle} track={props.track} isLocal={isLocal} priority={priority} />;
      {showContent &&
        <Content>
          <ParticipantUploadStatus participant={props.participant} />
        </Content>
      }
    </Container>
  );
}



export default ParticipantTrack;

const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

const Content = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
`
