import React from 'react';
import { Tooltip } from 'react-tippy';
import RecordingInfo from './RecordingInfo';
import ActionButton from './ui/ActionButton';
import styled from 'styled-components';


interface RecordingBlockProps {
  isHost: boolean;
  isRecording: boolean;
  isTesting: boolean;
  isStarting: boolean;
  scheduleRecording: (timestamp?: number) => void;
  scheduleTestRecording: (timestamp?: number) => void;
}

export default function RecordingBlock(props: RecordingBlockProps) {
  if (!props.isRecording && props.isHost) {
    return <ButtonContainer>
      {!props.isTesting && <ActionButton text={props.isStarting ? 'Starting...' : 'Start recording'} icon="circle" onClick={props.scheduleRecording} disabled={props.isStarting} /> }
      <Tooltip theme="light" title='Start a 10 second recording that you can check immediately.'>
        <TestRecording onClick={() => !props.isTesting ? props.scheduleTestRecording() : null}>{props.isTesting ? 'Starting' : 'Do a test recording'}</TestRecording>
      </Tooltip>
    </ButtonContainer>
  }

  if (props.isRecording) {
    return <RecordingInfo />;
  }

  return null;
}

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;

  > *:first-child {
    margin-right: 10px;
  }
`

const TestRecording = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: #FFFFFF;
  margin-left: 20px;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`