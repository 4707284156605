import React, { createContext, useCallback, useContext, useEffect, useMemo } from 'react';
import { useAppState } from './useAppState';
import logger from '../../util/logger';
import useUploader from "./useUploader";

export interface ParticipantInfo {
  id: string;
  uploadUrls: {
    localVideo: string;
  };
  uploadProgress: {
    totalSizeBytes: number;
    uploadedSizeBytes: number;
    finished: boolean;
  };
}

interface TwilioRoomAccess {
  twilioAccessToken: string;
  twilioRoomName: string;
}

interface SessionInfo {
  name: string;
  id: string;
  dashboardUrl: string;
  settings: {
    enableAudioPostProcessing: boolean;
  };
  sessionType: 'SINGLE_PLAYER' | 'MULTI_PLAYER';
}

export interface Session {
  sessionSlug: string;
  sessionInfo: SessionInfo;
  participantInfo: ParticipantInfo;
  hostToken: string | null;
}

interface SessionContextType extends Session {
  isHost: boolean;
  getAccessToken: () => Promise<TwilioRoomAccess>
}

export const SessionContext = createContext<SessionContextType>(null!);

export function useSession() {
  const context = useContext(SessionContext);
  if (!context) {
    throw new Error('useSession must be used within the SessionProvider');
  }

  return context;
}

export function SessionProvider(props: React.PropsWithChildren<{ session: Session }>) {
  const { resumeUploads } = useUploader();
  const state = useAppState();
  const isHost = useMemo(() => !!props.session.hostToken, [props.session.hostToken]);


  const getAccessToken = useCallback(async () => {
    const twilioRoomAccess = await state.api.getTwilioAccessInfo(props.session.participantInfo.id)
    return twilioRoomAccess as TwilioRoomAccess
  }, [state])

  useEffect(() => {
    return () => {
      state.quitSession();
    };
  }, []);

  useEffect(() => {
    logger.info('Trying to resume uploads if any exist.')
    resumeUploads()
  }, [resumeUploads])

  useEffect(() => {
    logger.info('Got participant', props.session.participantInfo.id);
  }, [props.session.participantInfo.id]);

  return (
    <SessionContext.Provider
      value={{
        sessionSlug: props.session.sessionSlug,
        hostToken: props.session.hostToken,
        participantInfo: props.session.participantInfo,
        sessionInfo: props.session.sessionInfo,
        isHost,
        getAccessToken
      }}
    >
      {props.children}
    </SessionContext.Provider>
  );
}
