import axios from 'axios';
import { debounce } from 'throttle-debounce';
import { getMachineIds } from '../app';

type LogFunction = (...args: any[]) => void;

interface ILogger {
  warn: LogFunction;
  info: LogFunction;
  error: LogFunction;
  debug: LogFunction;
}


let LOG_URL = 'https://api.getwelder.com/v1/client/log/';

if (process.env.WELDER_BUILD_ENVIRONMENT === 'prerelease' || process.env.NODE_ENV === 'development') {
  LOG_URL = 'https://api.staging.getwelder.com/v1/client/log/';
}

type LogLevels = 'WARN' | 'INFO' | 'ERROR' | 'DEBUG';

const pendingLogs: any[] = [];

const sendLogs = debounce(3000, async () => {
  const toSend = pendingLogs.splice(0);
  await axios.post(LOG_URL, toSend);
});

async function sendLog(level: LogLevels, ...args: any[]) {
  const payload = args.map((item) => {
    if (typeof item !== 'string') {
      return JSON.stringify(item);
    } else {
      return item;
    }
  });

  const log = {
    level: level,
    data: payload,
    variables: getMachineIds(),
    client: {
      name: 'web-client',
    },
  };

  pendingLogs.push(log);
  sendLogs();
}

export default {
  warn: (...data: any[]) => {
    console.warn(...data);
    sendLog('WARN', ...data);
  },
  info: (...data: any[]) => {
    console.log(...data);
    sendLog('INFO', ...data);
  },
  error: (...data: any[]) => {
    console.error(...data);
    sendLog('ERROR', ...data);
  },
  debug: (...data: any[]) => {
    console.log(...data);
    sendLog('DEBUG', ...data);
  },
} as ILogger;
