import { EventEmitter } from 'events';
import {
  getAllRecordingsIds,
  addApiIdToRecording,
  getRecording,
  getRecordingBlobsIdsByLimit,
  getBlob,
  deleteRecording,
  getRecordingBlobCount,
  deleteUploadedBlob,
  markBlobAsUploading,
  unmarkUploadingBlobs,
} from '../util/app/db';
import { api } from '../core/api';
import Sentry from '../util/sentry';
import logger from '../util/logger';
import { timeout } from '../util/app';
import CatchError from '../util/catchError';
import {IPlatformUploader, IUploadStats, RecordingStatus} from "../core/platform/PlatformUploader";
import moment from "moment";

export default class WebUploader extends EventEmitter implements IPlatformUploader {
  async checkForNewUploads() {
    // do nothing.
  }

  uploads: IUploadStats[] = [];
  recordingUploads: RecordingUpload[] = [];
  uploadStarted = false;
  paused = false;

  get hasActiveUploads() {
    return !!this.recordingUploads.length;
  }

  constructor() {
    super();
    setInterval(this.updateRecordingUploads.bind(this), 1000);
    setInterval(this.updateRecordingStats.bind(this), 1100);

    window.onbeforeunload = () => {
      if (!this.hasActiveUploads) {
        return;
      }

      return '';
    };
  }

  pauseUploads() {
    this.paused = true;
  }

  resumeUploads() {
    this.paused = false;
  }

  async updateRecordingStats() {
    const uploadStats: IUploadStats[] = [];
    for (const upload of this.recordingUploads) {
      const recording = await upload.getRecording();
      uploadStats.push({
        status: upload.status,
        uploadedSizeBytes: recording.uploadedBytes,
        totalSizeBytes: recording.totalBytes,
        filename: 'recording-' + recording.apiRecordingId,
        createdDateMs: recording.createdTimestamp,
      });
    }

    this.uploads = uploadStats;

    if (this.uploadStarted) {
      this.emit('upload-status-update', this.uploads);
    }
  }

  async updateRecordingUploads() {
    const recordingIds = await getAllRecordingsIds();
    for (const recordingId of recordingIds) {
      const hasRecording = this.recordingUploads.find((item) => item.recordingId === recordingId);
      if (!hasRecording) {
        logger.info(`Pushing new recording upload ${recordingId} to schedule`);
        const recording = new RecordingUpload(recordingId, this);
        this.recordingUploads.push(recording);
        recording.addListener('done', this.recordingUploadFinished.bind(this));
        recording.addListener('error', this.recordingError.bind(this));
        this.uploadStarted = true;
      }
    }
  }

  async recordingUploadFinished(recording: RecordingUpload) {
    recording.removeAllListeners();
    this.recordingUploads = this.recordingUploads.filter((item) => item !== recording);
    logger.info(`Recording ${recording.recordingId} upload finished`);
  }

  async recordingError(recording: RecordingUpload) {
    recording.removeAllListeners();
    this.recordingUploads = this.recordingUploads.filter((item) => item !== recording);
    logger.info(`Recording ${recording.recordingId} upload errored. Removed from uploads array.`);
  }
}

type StatusTypes = 'UPLOADING' | 'ERROR';
const CatchAllErrors = CatchError(Error, (err: Error, ctx: UploadTask) => ctx.onError(err));

class UploadTask extends EventEmitter {
  recordingBlobId: number;
  storageFileId: string;
  status: StatusTypes = 'UPLOADING';
  restarting = false;

  constructor(recordingBlobId: number, storageFileId: string) {
    super();
    this.recordingBlobId = recordingBlobId;
    this.storageFileId = storageFileId;
    this.startUpload();
  }

  @CatchAllErrors
  async startUpload() {
    this.restarting = false;

    await markBlobAsUploading(this.recordingBlobId);
    const recordingBlob = await getBlob(this.recordingBlobId);

    if (!recordingBlob) {
      logger.error('Could not find blob from DB', this.recordingBlobId);
      this.emit('error', this, new Error('BlobDoesNotExist'));
      return;
    }

    logger.info(`blob ${recordingBlob.orderingNumber} (id: ${this.recordingBlobId}) ready for upload with size: ${recordingBlob.blobSizeBytes} (actual: ${recordingBlob.buffer.byteLength})`)

    const storageFileChunk = await api.getStorageFileChunkUrl(this.storageFileId, recordingBlob.orderingNumber, recordingBlob.blobSizeBytes);
    const url = storageFileChunk.uploadUrl;
    await api.uploadStorageFileChunk(recordingBlob.buffer, url);
    await api.storageChunkUploadDone(storageFileChunk.id);
    logger.info(`uploaded blob ${this.recordingBlobId} with storageId ${this.storageFileId}`);

    try {
      await deleteUploadedBlob(recordingBlob.id!, this.storageFileId);
      logger.info(`deleted blob ${this.recordingBlobId} with storageId ${this.storageFileId}`);
    } catch (e) {
      logger.warn('Could not delete file: ', recordingBlob.id);
      throw e;
    }

    this.emit('upload-done', this);
  }

  async onError(e: Error) {
    this.status = 'ERROR';

    Sentry.captureException(e);
    logger.error(e);
    if (!this.restarting) {
      this.restarting = true;
      await timeout(6000);
      this.startUpload();
    }
  }
}

class RecordingUpload extends EventEmitter {
  recordingId: number;
  scheduledUploads: UploadTask[] = [];
  updateInterval: NodeJS.Timeout | undefined;
  createdStorageFile = false;
  finished = false;

  taskLimit = 4;
  uploader: WebUploader;

  get status(): RecordingStatus {
    const isUploading = this.scheduledUploads.some((item) => item.status === 'UPLOADING');
    const hasError = this.scheduledUploads.every((item) => item.status === 'ERROR') && this.scheduledUploads.length > 0;

    if (hasError) {
      return 'ERROR';
    }

    if (this.uploader.paused) {
      return 'PAUSED';
    }

    if (isUploading) {
      return 'UPLOADING';
    }

    if (this.finished) {
      return 'DONE';
    }

    return 'UPLOADING';
  }

  constructor(recordingId: number, uploader: WebUploader) {
    super();
    this.uploader = uploader;

    this.recordingId = recordingId;
    this.updateInterval = setInterval(this.updateTick.bind(this), 1000);
    this.createApiRecording();
    unmarkUploadingBlobs(this.recordingId);
  }

  async getRecording() {
    const recording = await getRecording(this.recordingId);
    return recording!;
  }

  async updateTick() {
    if (this.finished) {
      return;
    }

    if (this.uploader.paused) {
      return;
    }

    const recording = await getRecording(this.recordingId);
    if (!recording) {
      await this.onCriticalError(new Error('Recording does not exist in update tick'));
      return;
    }

    if (!this.createdStorageFile) {
      return;
    }

    await this.updateScheduledUploads();

    if (await this.checkIfFinished()) {
      logger.info('Recording finish check returned true.');
      await this.finishRecording();
    }
  }

  async createApiRecording() {
    try {
      const recording = await this.getRecording();

      if (!recording.storageFileId) {
        const data = await api.getOrCreateRecording(recording.participantId, recording.type, moment(recording.createdTimestamp).toISOString());
        await addApiIdToRecording(this.recordingId, data.storageFileId, data.id, data.sessionRecordingId);
        logger.info(`Recording and storage file created. ID: ${data.id} StorageFileId: ${data.storageFileId}`);
      }

      this.createdStorageFile = true;
    } catch (e) {
      logger.error(e);
      Sentry.captureException(e);
      await timeout(7000);
      this.createApiRecording();
    }
  }

  async checkIfFinished() {
    const recording = await getRecording(this.recordingId);
    const blobCount = await getRecordingBlobCount(this.recordingId);
    const uploadsInProgress = !!this.scheduledUploads.length;
    return blobCount === 0 && !uploadsInProgress && !recording?.recordingInProgress;
  }

  async finishRecording() {
    if (this.finished) {
      return;
    }

    this.finished = true;
    logger.info(`finishing recording ${this.recordingId}`);

    try {
      const recording = await this.getRecording();
      await api.finishWebRecording(recording.storageFileId!);
      await api.updateRecording(recording.apiRecordingId!, true);
      await deleteRecording(this.recordingId);

      if (this.updateInterval) {
        clearInterval(this.updateInterval);
      }

      this.emit('done', this);
    } catch (e) {
      this.finished = false;
      logger.error(e);
      logger.info('Cloud not finish recording');
      Sentry.captureException(e);
    }
  }

  async updateScheduledUploads() {
    const addToScheduled = this.taskLimit - this.scheduledUploads.length;
    if (addToScheduled === 0) {
      return;
    }

    const recording = await getRecording(this.recordingId);
    const recordingBlobsIds = await getRecordingBlobsIdsByLimit(this.recordingId, addToScheduled);

    for (const recordingBlobId of recordingBlobsIds) {
      const hasTask = this.scheduledUploads.find((item) => item.recordingBlobId === recordingBlobId);
      if (!hasTask) {
        const task = new UploadTask(recordingBlobId, recording!.storageFileId!);
        this.scheduledUploads.push(task);
        task.addListener('upload-done', this.taskFinished.bind(this));
        task.addListener('error', this.taskError.bind(this));
        logger.info(`Added ${task.recordingBlobId} task to recording upload schedule`);
      }
    }
  }

  async taskError(task: UploadTask, err: Error) {
    logger.warn(`error with upload task ${task.recordingBlobId} for recording ${this.recordingId}`);
    logger.error(err);
    Sentry.captureException(err);
    task.removeAllListeners();
    this.scheduledUploads = this.scheduledUploads.filter((item) => item !== task);
  }

  async taskFinished(task: UploadTask) {
    task.removeAllListeners();
    this.scheduledUploads = this.scheduledUploads.filter((item) => item !== task);
    logger.info(`finished with upload task ${task.recordingBlobId} for recording ${this.recordingId}`);
    logger.info(`Blobs remaining in database: ${await getRecordingBlobCount(this.recordingId)} and currently uploading: ${this.scheduledUploads.length}`);
  }

  async onCriticalError(e: Error) {
    logger.error(`Critical error occurred in recording ${this.recordingId} | Uploads in progress: ${this.scheduledUploads}`);
    logger.error(e);
    Sentry.captureException(e);
    this.emit('error', this, e);
  }
}
