import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
// @ts-ignore
import { useAppState } from '../useAppState';
import { LocalAudioTrack, LocalVideoTrack } from 'twilio-video';
import useDataChannel, { MessageMetadata } from '../useDataChannel';
import moment from 'moment';
import { timeout, waitTill } from '../../../util/app';
import logger from '../../../util/logger';
import { useSession } from '../useSession';
import { usePlatform } from '../platform/usePlatform';
import { RecordingErrorTypes, RecordingType } from '../../platform/PlatformRecorder';
import { toast } from 'react-toastify';
import useVideoContext from '../video/useVideoContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRecording } from "../../../util/app/db";

export function useRecorder() {
  const context = useContext(RecorderContext);
  if (!context) {
    throw new Error('useRecorder must be used within the RecorderProvider');
  }
  return context;
}

interface RecorderContextType {
  isRecording: boolean;
  scheduleRecording: (timestamp?: number) => void;
  isStarting: boolean;
  isStopping: boolean;
  isTesting: boolean;
  recordingStartTime: number | null;
  safeRecordingStop: () => void;
  stopRecording: () => Promise<void>;
  scheduleTestRecording: () => void;
  hasActiveRecording: boolean;
  testingModalOpen: boolean;
  toggleTestingModal: (toggle: boolean) => void;
  getRecordingsInfo: () => Promise<IRecording[]>;
}

export const RecorderContext = createContext<RecorderContextType>(null!);

export function RecorderProvider(props: React.PropsWithChildren<any>) {
  const TESTING_TIME_MS = 7_000

  const { api } = useAppState();
  const session = useSession();
  const { platformRecorder } = usePlatform();
  const { localTracks } = useVideoContext()

  const [isRecording, toggleRecording] = useState(false);
  const [isTesting, toggleIsTesting] = useState(false);
  const [testingModalOpen, toggleTestingModal] = useState(false);
  const [hasActiveRecording, setActiveRecording] = useState(false);
  const [isStarting, setIsStarting] = useState(false);
  const [isStopping, setIsStopping] = useState(false);
  const dataChannel = useDataChannel();
  const [recordingStreams, setRecordingStreams] = useState<{[type in RecordingType]: MediaStream}>({'VIDEO_SCREEN_LOCAL': null, 'VIDEO_LOCAL': null})
  const [startTime, setStartTime] = useState<number | null>(null)
  const recordingTime = useRecordingTime();

  useEffect(() => {
    const recordingTracks: (LocalAudioTrack | LocalVideoTrack)[] = localTracks
      .filter(track => {
        return track instanceof LocalVideoTrack || track instanceof LocalAudioTrack
      }) as any


    const participantTracks = recordingTracks.filter(track => track.name.includes('camera') || track.name.includes('microphone'))
    const screenTracks = recordingTracks.filter(track => track.name.includes('screenshare'))

    let screenStream = screenTracks.length > 0 ? new MediaStream(screenTracks.map((track: any) => track.mediaStreamTrack)) : null
    const participantStream = participantTracks.length > 0 ? new MediaStream(participantTracks.map((track: any) => track.mediaStreamTrack)) : null

    if (session.sessionInfo.sessionType === 'MULTI_PLAYER') {
      screenStream = null; 
    }

    setRecordingStreams({
      VIDEO_LOCAL: participantStream,
      VIDEO_SCREEN_LOCAL: screenStream
    })

    console.log({
      VIDEO_LOCAL: participantStream,
      VIDEO_SCREEN_LOCAL: screenStream
    })
  }, [localTracks, session.sessionInfo])


  const startRecording = useCallback(async () => {
    recordingTime.start();
    setIsStarting(false);
    toggleRecording(true);
    setActiveRecording(true);

    for await (let [key, value] of Object.entries(recordingStreams)) {
      if (!value) {
        continue;
      }

      platformRecorder.addRecordingStream(value, session.participantInfo.id, key as any)
    }

    console.time('start recording')
    await platformRecorder.startRecording();
    console.timeEnd('start recording')
  }, [isStarting, platformRecorder, session.participantInfo.id, recordingStreams]);

  const stopRecording = useCallback(async () => {
    recordingTime.end();
    platformRecorder.stopRecording();
    toggleRecording(false);
    setIsStopping(false);
    logger.info('Recording is stopped...');
  }, [platformRecorder, isRecording, isStopping, isTesting]);

  const scheduleRecording = useCallback(
    async (timestamp?: number) => {
      const startRecordingTime = timestamp || moment().add(5, 'seconds').valueOf();

      if (session.isHost && !timestamp) {
        api.updateSession(session.sessionInfo.id, {
          recordingEnabled: true,
          state: 'IN_PROGRESS',
        });

        dataChannel.broadcastMessage({
          type: 'start-recording',
          value: startRecordingTime,
        });
      }

      logger.info(`Recording starting in ${startRecordingTime}. Is host: ${session.isHost}`);

      setStartTime(startRecordingTime)
      setIsStarting(true);

      await waitTill(startRecordingTime)
      startRecording()
    },
    [session.isHost, dataChannel.broadcastMessage, startRecording, setStartTime]
  );

  const scheduleRecordingStop = useCallback(
    async (timestamp?: number, isTest = false) => {
      const stopTime = timestamp || moment().add(3, 'seconds').valueOf();

      if (session.isHost && !timestamp) {
        api.updateSession(session.sessionInfo.id, {
          recordingEnabled: false,
          state: 'FINISHED',
        });

        dataChannel.broadcastMessage({
          type: isTest ? 'stop-test-recording' : 'stop-recording',
          value: stopTime,
        });
      }

      logger.info(`Stopping recording in ${stopTime}. Is host: ${session.isHost}`);

      setIsStopping(true);
      await waitTill(stopTime);
      await stopRecording();
    },
    [session.isHost, dataChannel.broadcastMessage, stopRecording]
  );

  const safeRecordingStop = useCallback(async (timestamp?: number) => {
    logger.info('Stopping recording safely')
    await scheduleRecordingStop(timestamp)
    toast.info(<span>Good job, the recording is done! Please leave through leave button <FontAwesomeIcon icon='sign-out' /> to finish uploading properly.</span>)
  }, [scheduleRecordingStop])

  const scheduleTestRecording = useCallback(
    async () => {
      const futureStartSeconds = 5
      scheduleRecording()
      toggleIsTesting(true)
      await timeout(TESTING_TIME_MS + (futureStartSeconds * 1000))
      await scheduleRecordingStop(undefined, true)
      toggleIsTesting(false)
      toggleTestingModal(true)
    }, [scheduleRecording, toggleIsTesting, scheduleRecordingStop, toggleTestingModal, isTesting])

  const remoteParticipantError = useCallback(
    async (errorType: RecordingErrorTypes, metadata: MessageMetadata) => {
      logger.warn('Remote participant got an error');

      if (errorType === 'blob-save-error') {
        toast.error('All recordings forcefully stopped. Likely one of the participants has no more available disk space.');
      } else if (errorType === 'recording-start-no-device-error') {
        toast.error('Recording could not be started. Likely one of the recording devices (microphone or camera) is not working properly for one your participants.');
      } else if (errorType === 'recording-start-generic-error') {
        toast.error('Recording for one of the call participants could not be started. Please tell him to refresh the page.');
      }

      api.updateSession(session.sessionInfo.id, {
        recordingEnabled: false,
        state: 'FINISHED',
      });

      await stopRecording();
    },
    [stopRecording, api.updateSession]
  );

  useEffect(() => {
    dataChannel.on('start-recording', scheduleRecording);
    dataChannel.on('stop-recording', safeRecordingStop);
    dataChannel.on('stop-test-recording', scheduleRecordingStop)
    dataChannel.on('recording-error', remoteParticipantError);

    return () => {
      dataChannel.off('start-recording', scheduleRecording);
      dataChannel.off('stop-recording', safeRecordingStop);
      dataChannel.off('stop-test-recording', scheduleRecordingStop)
      dataChannel.off('recording-error', remoteParticipantError);
    };
  }, [scheduleRecording, safeRecordingStop]);

  const onRecordingError = useCallback(async (errorType: RecordingErrorTypes) => {
    if (errorType === 'blob-save-error') {
      toast.error('Your recording was forcefully stopped. Do you have enough disk space?');
    } else if (errorType === 'recording-start-no-device-error') {
      toast.error('Could not start recording. Is your camera and microphone working properly?');
    } else if (errorType === 'recording-start-generic-error') {
      toast.error('Could not start recording... Please try to refresh the page and try again.');
    }

    dataChannel.broadcastMessage({
      type: 'recording-error',
      value: errorType,
    });

    api.updateSession(session.sessionInfo.id, {
      recordingEnabled: false,
      state: 'FINISHED',
    });

    await stopRecording();
  }, [dataChannel.broadcastMessage, api.updateSession, stopRecording])

  useEffect(() => {
    platformRecorder.on('error', onRecordingError);

    return () => {
      platformRecorder.off('error', onRecordingError);
    };
  }, [platformRecorder, onRecordingError]);

  const getRecordingsInfo = useCallback(async () => {
    return platformRecorder.getRecordingsInfo();
  }, [api]);


  return (
    <RecorderContext.Provider
      value={{
        isRecording,
        scheduleRecording,
        isStarting,
        isStopping,
        isTesting,
        recordingStartTime: startTime,
        safeRecordingStop,
        stopRecording,
        scheduleTestRecording,
        hasActiveRecording: hasActiveRecording,
        testingModalOpen,
        toggleTestingModal,
        getRecordingsInfo: getRecordingsInfo
      }}
    >
      {props.children}
    </RecorderContext.Provider>
  );
}

export function useExternalRecordingState() {
  const { api } = useAppState();
  const recording = useRecorder();
  const session = useSession();

  const getRecordingState = useCallback(async () => {
    const sessionData = await api.getSession(session.sessionInfo.id);
    return { state: sessionData.state, recordingEnabled: sessionData.recordingEnabled };
  }, [session.sessionInfo.id]);

  return useCallback(async () => {
    const status = await getRecordingState();
    if (status.recordingEnabled && !recording.isRecording) {
      const timestamp = moment().add(5, 'seconds').valueOf();
      recording.scheduleRecording(timestamp);
    }
  }, [getRecordingState, recording.isRecording, recording.scheduleRecording]);
}

export function useRecordingTime() {
  const [startTime, setStartTime] = useState<moment.Moment | null>(null);

  const start = useCallback(() => {
    setStartTime(moment())
  }, [setStartTime])

  const end = useCallback(() => {
    setStartTime(null)
  }, [setStartTime])

  return {
    startTime,
    start,
    end
  }
}

export function useRecordingCounter() {
  const [time, setTime] = useState<string | null>(null);

  const { recordingStartTime } = useRecorder();

  useEffect(() => {
    if (!recordingStartTime) {
      setTime(null)
      return
    }

    const interval = setInterval(() => {
      const currentTime = moment()
      const startTime = moment(recordingStartTime)
      setTime(moment.utc(currentTime.diff(startTime)).format('HH:mm:ss'))
    }, 1000)

    return () => {
      clearInterval(interval)
    }
  }, [recordingStartTime])

  return time
}
