import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import GridItem from '../../../components/layout/GridItem';
import { useAllRemoteTracks } from '../../../hooks/participants/participant';
import ParticipantTrack from '../../../components/ParticipantTrack';
import Waiting from '../../../components/layout/Waiting';
import { useTransition, animated } from 'react-spring';
import useVideoContext from '../../../hooks/video/useVideoContext';

export default function GridLayout() {
  const [itemsWidth, setItemsWidth] = useState(0);
  const [itemsHeight, setItemsHeight] = useState(0);
  const [containerWidth, setContainerWidth] = useState(0);

  const {
    room: { localParticipant },
    localTracks,
  } = useVideoContext();
  const remoteTracks = useAllRemoteTracks();
  const videoFeeds = remoteTracks.filter((item) => item.track.kind === 'video');
  const firstVideoFeed = videoFeeds.shift();

  const items: JSX.Element[] = [];

  items.push(
    ...localTracks
      .filter((track) => track.kind === 'video')
      .map((track) => {
        return (
          <GridItem width={itemsWidth} height={itemsHeight} key={track.id}>
            <ParticipantTrack participant={localParticipant} track={track as any} />
          </GridItem>
        );
      })
  );

  let SecondItem = (
    <GridItem width={itemsWidth} height={itemsHeight} key="waiting-for-participant">
      <Waiting />
    </GridItem>
  );

  if (firstVideoFeed) {
    SecondItem = React.cloneElement(SecondItem, undefined, <ParticipantTrack participant={firstVideoFeed.participant} track={firstVideoFeed.track as any} />);
  }

  items.push(SecondItem);

  for (const feed of videoFeeds) {
    items.push(
      <GridItem width={itemsWidth} height={itemsHeight} key={feed.track.sid}>
        <ParticipantTrack participant={feed.participant} track={feed.track as any} />
      </GridItem>
    );
  }

  const totalGridItems = items.length;

  const setGridSizing = useCallback(() => {
    const sideMargin = 5;
    const maxRowItems = 2;

    const windowHeight = window.innerHeight;
    const windowWidth = window.innerWidth;

    const itemAspectRatio = 16 / 10;
    const maxWidthItemWidth = (windowWidth - sideMargin * maxRowItems * 3) / maxRowItems;

    let itemWidth = maxWidthItemWidth;
    let itemHeight = maxWidthItemWidth / itemAspectRatio;

    const gridItemColumnCount = Math.ceil(totalGridItems / maxRowItems);

    const gridItemsHeight = itemHeight * gridItemColumnCount;
    const gridContentHeight = gridItemsHeight + gridItemColumnCount * sideMargin * 2;
    const gridHeight = gridContentHeight + sideMargin * 2;

    if (gridHeight > windowHeight) {
      const offsetMax = 0.035;
      let heightDifference = gridHeight / windowHeight;
      heightDifference = heightDifference * (1 + (heightDifference / 3) * offsetMax);
      itemHeight = itemHeight / heightDifference;
      itemWidth = itemHeight * itemAspectRatio;
    }

    setItemsWidth(itemWidth);
    setItemsHeight(itemHeight);
    setContainerWidth(containerWidth);
  }, [totalGridItems]);

  useEffect(() => {
    window.addEventListener('resize', setGridSizing);
    setGridSizing();

    return () => {
      window.removeEventListener('resize', setGridSizing);
    };
  }, [setGridSizing]);

  const transitions = useTransition(items, (item: any) => item.key, {
    from: { transform: 'scale(0.9)', opacity: 0 },
    enter: { transform: 'scale(1)', opacity: 1 },
    leave: { transform: 'scale(0.7)', opacity: 0 },
  });

  if (!itemsWidth) {
    return null;
  }

  return (
    <Container>
      {transitions.map(({ item, props, key }) => (
        <animated.div key={key} style={props}>
          {item}
        </animated.div>
      ))}
    </Container>
  );
}

const Container = styled.div`
  margin: 0 auto;
  display: flex;
  padding: 5px;
  flex-wrap: wrap;
  justify-content: center;
`;
